.launch-badge {
  display: inline-flex;
  gap: 4px;
  padding: 4px 8px;
  align-items: center;
  border-radius: 8px;
  font-size: 11px;
  font-style: normal;
  font-family: $font-700;
  line-height: 24px;
  text-transform: uppercase;
  &.outlined {
    border: 1px solid $green-200;
    color: $green-200;
    background: $white;
  }
  &.outlined-warn {
    border-color: $peach;
    color: $secondary ;
    font-size: 14px;
    display: flex;
    align-items: center;
  }
  &.filled {
    background: $green-200;
    color: $white;
  }
  @media only screen and (min-width:375px) {
    font-size: 16px;
  }
  @media only screen and (min-width:1200px) {
    font-size: 18px;
    height: 35px;
    padding: 7px 12px;
  }
}

@keyframes blinker {
  50% {
    background: $black-500;
  }
}

.hompage-ext-cross-sell {
  background: $pr-60;
  color: $secondary;
  overflow: hidden;
  border-radius: 0px 0px 27.307px 27.307px;
  // &.cross-sell-desktop {
  // }
  @media only screen and (min-width:375px) {
    border-radius: 0px 0px 32px 32px;
  }
  @media only screen and (min-width:768px) {
    border-radius: unset;
  }
  .title {
    font-size: 28px;
    font-style: normal;
    font-family: $font-800;
    line-height: 110%;
    letter-spacing: 0.128px;
    @media only screen and (min-width:375px) {
      font-size: 32px;
    }
    // @media only screen and (min-width:768px) {
    //     font-size: 32px;
    // }
  }
  .description {
    color: $se-800;
    font-size: 14px;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0.128px;
    @media only screen and (min-width:375px) {
      font-size: 16px;
    }
  }
  .action-btn {
    display: inline-flex;
    height: 48px;
    padding: 12px 16px;
    // flex-direction: column;
    align-items: center;
    // gap: 8px;
    // align-self: stretch;
    border-radius: 24px;
    background: $secondary;
    color: $white;
    font-size: 16px;
    font-style: normal;
    font-family: $font-700;
    line-height: 150%;
    span {
      font-family: $primary-font;
    }
  }
  .help-q {
    leading-trim: both;
    text-edge: cap;
    font-size: 14px;
    font-family: $font-700;
    font-style: normal;
    line-height: 143%;
    letter-spacing: 0.17px;
  }
  .help-a {
    .play-icon {
      width: 18px;
      height: 18px;
      margin-right: 7px;
    }
    span {
      leading-trim: both;
      text-edge: cap;
      font-size: 14px;
      font-style: normal;
      // font-weight: 400;
      line-height: 143%;
      letter-spacing: 0.17px;
      text-decoration-line: underline;
    }
  }
  &.ios-not-active {
    padding: 18px 17px 23px 17px;
    position: relative;
    .title {
      margin: 22px 0px 19px;
      position: relative;
      padding-right: 90px;
      .safari-icon {
        position: absolute;
        right: 0;
        top: -10px;
        opacity: 0.3;
      }
    }
    .description {
      margin-bottom: 19px;
      padding-right: 50px;
    }
    .action-btn {
      margin-bottom: 20px;
    }
    .lb-extension-screen {
      position: absolute;
      bottom: 0;
      width: 90px;
    }
    .lb-extension {
      position: static;
    }
    .safari-icon {
      width: 75px;
      height: 75px;
    }
    @media only screen and (min-width:375px) {
      padding: 30px;
      .title {
        margin: 12px 0px 12px;
        padding-right: 90px;
        .safari-icon {
          width: 90px;
          right: -10px;
        }
      }
      .description {
        padding-right: 65px;
      }
      .lb-extension-screen {
        display: none;
      }
      .safari-icon {
        width: 92px;
        height: 92px;
      }
    }
    @media only screen and (min-width:768px) {
      padding: 30px 110px 44px 110px;
      background-image: url("../../images/cloud.png"),
        url("../../images/cloud.png");
      background-repeat: no-repeat;
      background-size: 153px, 233px;
      background-position: left -58px top -5px, left 330px bottom -83px;
      .lb-extension-screen {
        display: block;
      }
      .lb-extension-screen {
        width: 230px;
        bottom: -60px;
        right: 30px;
        width: 190px;
      }
      .title {
        padding-right: 100px;
        max-width: 500px;

        margin: 19px 0px 19px;
        .safari-icon {
          width: 100px;
          height: 100px;
        }
      }
      .description {
        padding-right: 130px;
        max-width: 500px;
        margin-bottom: 20px;
      }
      .help-info {
        display: inline-block;
        vertical-align: middle;
        margin-left: 15px;
      }
      .action-btn {
        margin-bottom: 0px;
      }
    }
    @media only screen and (min-width:991px) {
      padding: 30px 110px 44px 110px;
      background-image: url("../../images/cloud.png"),
        url("../../images/cloud.png");
      background-repeat: no-repeat;
      background-size: 153px, 233px;
      background-position: left -58px top -5px, left 430px bottom -83px;
      .lb-extension-screen {
        display: block;
      }
      .lb-extension-screen {
        width: 230px;
        bottom: -60px;
        width: 200px;
      }
      .title {
        padding-right: 168px;
        max-width: 500px;
        font-size: 38px;
        margin: 19px 0px 19px;
        .safari-icon {
          width: 158px;
          height: 154px;
        }
      }
      .description {
        padding-right: 168px;
        max-width: 500px;
        margin-bottom: 20px;
      }
      .help-info {
        display: inline-block;
        vertical-align: middle;
        margin-left: 15px;
      }
      .action-btn {
        margin-bottom: 0px;
      }
    }
  }

  &.ios-active {
    padding: 24px 10px 24px 10px;
    .title {
      margin-bottom: 13px;
    }
    .description {
      margin-bottom: 18px;
    }

    .bold-desc {
      font-family: $font-600;
    }

    .bird-fly-icon{
      position: absolute;
      left: 30%;
      top: 15px;
      height: 67px;
      width: 240px;
    }

    .lb-extension-screen {
      position: absolute;
      bottom: -30px;
      right: 0;
      width: 67px;
      height: auto;
    }
    .action-btn {
      margin-bottom: 0;
      height: 32px;
      padding: 12px 32px;
      border: None;
      font-size: 12px;

      &.big {
        height: 48px;
        justify-content: center;
        font-size: 14px;
        font-family: $font-600;
        margin-bottom: 20px;
        width: auto;
      }
    }
    .ios-active__form {
      border-radius: 24px;
      border: 1px solid $secondary;
      background: $white;
      height: 48px;
      width: 100%;
      overflow: hidden;
      padding: 0 10px;
      .form-control {
        padding: 0.375rem 0.35rem;
        font-size: 14px;
        line-height: 171.429%;
        color: $black-500;
        &::-webkit-input-placeholder {
          color: $black-500 !important;
        }
        &::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: $black-500 !important;
          opacity: 1; /* Firefox */
        }

        &::-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: $black-500 !important;
        }

        &::-ms-input-placeholder {
          /* Microsoft Edge */
          color: $black-500 !important;
        }
        &:focus {
          box-shadow: none;
        }
      }
      .input-group-text,
      .form-control {
        border: none;
      }
      .input-group-append {
        margin-left: 0;
        .input-group-text {
          padding: 5px;
        }
        img {
          height: 25px;
          width: 25px;
          margin: auto;
        }
      }
      .input-group-prepend {
        height: auto;
        margin-right: 0;
        // .input-group-text{
        position: relative;
        padding-right: 5px;
        &:after {
          content: "";
          width: 1px;
          height: 18px;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          z-index: 1;
          // background: $black-500;
          animation: blinker 1s linear infinite;
        }
        &.focus {
          &:after {
            display: none;
          }
        }
        // }
      }
    }
    @media only screen and (min-width:375px) {
      padding: 35px 10px 24px 30px;
      .action-btn {
        display: flex;
        margin: auto;
      }

      // .lb-extension-screen {
      //   width: 67px;
      //   height: auto;
      // }
    }
    @media only screen and (min-width:768px) {
      // padding: 35px 10px 0 30px;
      padding: 30px 110px 0 110px;
      background-image: url("../../images/cloud.png"),
        url("../../images/cloud.png");
      background-repeat: no-repeat;
      background-size: 153px, 233px;
      background-position: left -58px top -5px, left 430px bottom -83px;
      .action-btn {
        display: inline-flex;
        margin-bottom: 48px;
        // margin: auto;
      }
      .title {
        font-size: 38px;
      }
      .description {
        margin-bottom: 48px;
      }
      .lb-extension-screen {
        position: absolute;
        bottom: 0;
        right: auto;
        width: 180px;
        left: 35px;
        // bottom: -30px;
        // right:0;
      }
    }
  }
  &.ios-view {
    // padding: 18px 10px 0px 25px;
    padding: 51px 10px 0px 30px;
    .title {
      margin-top: 12px;
      margin-bottom: 26px;
      max-width: 320px;
    }
    .description {
      margin-bottom: 24px;
      padding-right: 10px;
      max-width: 185px;
    }
    .action-btn {
      margin-bottom: 8.5px;
      border: none;
    }
    .lb-extension-screen {
      margin-top: -20px;
      position: absolute;
      bottom: 0;
      width:  106px;
      right: 5px;
    }
    &.cross-sell-desktop {
      padding-top: 110px;
      padding-bottom: 95px;
      margin: 0 auto;
      background-image: url('../../../assets/images/cloud.png'), url("../../images/lb-bird-vector.svg"), url('../../../assets/images/cloud.png'), url('../../../assets/images/cloud.png');
      background-repeat: no-repeat;
      background-size: 200px, 350px, 300px, 200px;
      background-position: 2% 50%, 40% 120%, 39% 120%, 108% 50%;
      .text-content {
        padding-left: 5%;
        .title {
          font-size: 48px;
          max-width: 500px;
          line-height: 54px;
        }
        .description {
          max-width: 307px;
          font-size: 16px;
        }
        @media only screen and (min-width:1600px) {
          padding-left: 10%;
        }
        @media only screen and (min-width:1920px) {
          padding-left: 15%;
        }
      }
      .delivery-msg p {
        margin-top: 14px;
        color: rgba(0, 0, 0, 0.60);
        font-size: 12px;
        font-style: italic;
        font-weight: 400;
      }
      @media only screen and (min-width:1600px) {
        padding-left: 10%;
        padding-right: 10%;
        background-size: 250px, 400px, 350px, 250px;
        background-position: 2% 50%, 40% 120%, 40% 118%, 108% 50%;;
      }
      @media only screen and (min-width:1920px) {
        background-size: 300px, 400px, 400px, 300px;
        background-position: 2% 50%, 44% 120%, 43% 125%, 108% 50%;;
      }
      .second-col {
        margin-left: 10%;
        .store-gif {
            width: 90%;
            max-width: 510px;
          }
      }
    }
    .help-info {
      margin-bottom: 10px;
    }
    @media only screen and (min-width:375px) {
      // padding: 30px 10px 0px 30px;
      .ext-detail {
        padding-bottom: 56px;
      }
      &.android-view {
        .ext-detail {
          padding-bottom: 80px;
        }
      }
      .lb-extension-screen {
        height: 100%;
        width: auto;
        // margin-top: -20px;
        // position: absolute;
        // bottom: 0;
      }
      .title {
        padding-right: 25px;
        max-width: unset;
      }
      .description {
        padding-right: 50px;
        max-width: unset;
      }
      .action-btn {
        margin-bottom: 20px;
      }
    }
    @media only screen and (min-width:768px) {
      background-image: url("../../images/cloud.png"),
        url("../../images/cloud.png"), url("../../images/lb-bird-vector.svg");
      background-repeat: no-repeat;
      background-size: 153px, 233px, 250px;
      background-position: left -58px top -5px, left 280px bottom -83px,
        left 280px bottom -83px;
      // padding: 30px 10px 0px 30px;
      padding: 30px 110px 0 110px;
      .title {
        font-size: 38px;
        max-width: 350px;
      }
      .title,
      .description {
        margin-bottom: 15px;
      }
      .lb-extension-screen {
        width: 240px;
        left: 40px;
        // margin-top: -20px;
        // position: absolute;
        // bottom: 0;
        // width: 120px;
        // position: relative;
      }
      .action-btn {
        display: flex;
        margin-bottom: 5px;
        span {
          font-family: $primary-font;
        }
      }
      .action-btn.only-mobile {
        padding: 0;
        height: auto;
        background: none;
        color: $secondary;
        font-family: $primary-font;
        text-decoration: underline;
      }
    }
    @media only screen and (min-width:991px) {
      .lb-extension-screen {
        width: 300px;
        left: 0px;
      }
      .description {
        padding-right: 45px;
      }
      background-position: left -58px top -5px, left 340px bottom -83px,
        left 340px bottom -83px;
      background-size: 153px, 233px, 300px;
    }
  }

  &.permission-required {
    padding: 51px 10px 10px 20px;
    position: relative;

    .title {
      margin-top: 26px;
      margin-bottom: 20px;
      max-width: 360px;
    }
    .description {
      margin-bottom: 24px;
      margin-right: 10%;
    }
    .action-btn {
      margin-bottom: 20px;
      margin-top: 10px;
      margin-left: 0px;
      padding: 12px 16px;
      font-size: 16px;
      font-family: $font-700;
      height: 48px;
    }
    .privacy-text {
      font-size:14px;
      margin-bottom:20px;
      line-height:20px;
    }
    .privacy-link {
      font-family: $font-700;
    }
    .warn-icon{
      height: 24px;
      width: 24px;
    }

    @media only screen and (min-device-width: 768px){
      .title{
        max-width: none;
      }
      .description{
        margin-left: 8px;
        margin-top: -9px;
      }
      .warn-icon{
        height: 34px;
        width: 34px;
      }
    }

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
      padding-left: 50px;
      background-position: left -94px top 74px, left 430px bottom -83px;
    }
  }


  &.ipad-view {
    .bird-fly-icon{
      height: 196px;
      width: 700px;
      top: 75%;
      left: 10%;
    }
    .description{
      margin-bottom: 5px;
      font-weight: 400;
    }
    .help-info{
      margin-bottom: 30px;
    }
    .clock-img{
      margin-top: 30px;
    }

    @media only screen and (min-width:768px) {
      background-repeat: no-repeat;
      background-size: 153px, 270px;
      background-position: left -58px top 40px, left 500px bottom -83px;
    }
  }
}

.iphone-ext-activation-video-modal {
  background-color: transparent !important;
  .modal-content {
      background-color: transparent !important;
      border: 0;
      .modal-body {
        text-align: center;
        background-color: transparent !important;
        padding: 0;
        video {
          width: 100%;
          height: 100%;
          max-width: 85%;
        }
        .close-btn {
          border: 0;
          left: 50%;
          transform: translate(-50%, 0px);
          top: -35px;
          position: absolute;
          background: transparent;
          color: $white;
          font-size: 16px;
          font-family: $font-700;
          line-height: 150%;
          img {
            width: 16px;
            height: 16px;
            margin-right: 3px;
          }

        }
        .action-btn {
          border: none;
          height: 48px;
          padding: 12px 16px;
          align-items: center;
          border-radius: 24px;
          background: $secondary;
          color: $white;
          font-size: 16px;
          font-style: normal;
          font-family: $font-700;
          line-height: 150%;
        }
      }
  }
}
