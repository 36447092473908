
.bg-peach-500 {
    background-color: $peach-500;
}

.bg-peach {
    background-color: #fbe5de;
}

.bg-peach-700 {
    background-color: $peach-700;
}

.border-t-0 {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

.text-pr-500 {
    color: $pr-500;
}
.narrative {
    overflow: hidden;
    position: relative;
    margin-top: -56px;
    background-color: #ffffff;
    @media (min-width: 1200px) {
        margin-top: -88px;
    }
    >.body {
        display: block;
        position: relative;
        background-color: #ffffff;
        z-index: 110;
        margin-top: 20px;
        padding-bottom: 120px;
        border-top-left-radius: 32px;
        border-top-right-radius: 32px;

        @media (min-width: 768px) {
            border-radius: 0px;
            box-shadow: none;
            padding-top: 40px;
            margin-top: 0px;
        }
    
        @media (min-width: 1400px) {
            border-radius: 0px;
            box-shadow: none;
            padding-top: 40px;
            margin-top: 0px;
        }
    }
}

.nar-lable {
    font-size: 16px;
    line-height: 20px;
    text-decoration: underline;
    padding-top: 12px;
    font-family: $font-700;

    img {
        height: 18px;
        @media (min-width: 768px) {
            height: 24px;
        }
    }
    @media (min-width: 768px) {
        font-size: 24px;
        a {
            margin-right: 8px;
        }
    }
    @media (min-width: 1400px) {
        font-size: 32px;
    }
}


.narrative-section {
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08), 0px 10px 16px -4px rgba(0, 0, 0, 0.1);
    border-radius: 32px;
    margin-bottom: 20px;
    overflow: visible;
    position: relative;
    z-index: 100;

    .highlighter {
        color: $pr-500;
    }

    .btn {
        margin-bottom: 10px;
        color: $se-800 !important;
        line-height: 24px;
    }

    @media (min-width: 768px) {
        border-radius: 0px;
        box-shadow: none;
        margin-bottom: 40px;
    }

    @media (min-width: 1400px) {
        border-radius: 0px;
        box-shadow: none;
        margin-bottom: 40px;
    }

    .mobile-show {
        display: block;
        @media (min-width: 768px) {
            display: none;
        }
    }

    .desktop-show {
        display: none;
        @media (min-width: 768px) {
            display: block;
        }
        button {
          width: 230px;
        }
    }
    .paragraph {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      padding-bottom: 3rem;
      max-width: 22rem;
      @media (min-width: 768px) {
          padding-bottom: 50px;
      }
      @media (max-width: 1199px) {
        &.fav-brands {
          max-width: 17rem;
        }
      }
      @media (min-width: 1200px) {
        font-size: 24px;
        line-height: 32px;
        max-width: 35rem;
      }
    }

    &.bg-cloud {
        z-index: 100;
        &::before {
        content: '';
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-image: url('../../../assets/images/cloud2.png'), url('../../../assets/images/cloud2.png');
        background-size: 14rem, 22rem;
        background-repeat: no-repeat, no-repeat;
        background-position: top 6.7rem left -4rem, top 18rem right -9rem;
        // background-attachment: fixed;
        z-index: -2;
        @media (min-width: 768px) {
            background-size: 12rem, 20rem;
            background-position: top 5.5rem left 2rem, top 7rem right 0rem;
        }
        @media (min-width: 992px) {
            background-image: url('../../../assets/images/cloud2.png'), url('../../../assets/images/cloud2.png'), url('../../../assets//images/rays.svg');
            background-size: 18rem, 26rem, 100%;
            background-position: top 5.5rem left 5rem, top 7rem right 0rem, 0rem center;
        }
        @media (min-width: 1400px) {
            background-image: url('../../../assets/images/cloud2.png'), url('../../../assets/images/cloud2.png'), url('../../../assets//images/rays.svg');
            background-size: 31.563rem, 40.063rem, 100%;
            background-position: top 4.375rem left 13.75rem, top 6rem right -2rem, 0rem center;
        }
        }
        .told-me {
          max-width: 15rem;
          @media (min-width: 375px){
            max-width: 18;
          }
          @media (min-width: 768px){
            max-width: 50rem;
          }
        }
    }

    &.shopping-bg {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        &::before {
            @media (min-width: 992px) {
            content: '';
            position: absolute;
            background-image: url('../../../assets/images/cloud2.png');
            background-size: 22rem;
            background-repeat: no-repeat;
            background-position: top 0rem left 5.438rem;
            overflow: visible !important;
            width: 100%;
            height: 400px;
            top: -45px;
        }
        }
    }

    &.discover-bg {
        position: relative;
        &::before {
            @media (min-width: 992px) {
            content: '';
            position: absolute;
            background-image: url('../../../assets/images/cloud2.png'), url('../../../assets/images/cloud2.png');
            background-size: 18%, 24%;
            background-repeat: no-repeat;
            background-position: left 2rem top 2rem, right 0rem top 0rem;
            overflow: visible !important;
            width: 100%;
            height: 300px ;
            top: -100px;
            left: 0px;
        }
        @media (min-width: 1400px) {
            content: '';
            position: absolute;
            background-image: url('../../../assets/images/cloud2.png'), url('../../../assets/images/cloud2.png');
            background-size: 18%, 24%;
            background-repeat: no-repeat;
            background-position: left 2rem top 2rem, right 0rem top 0rem;
            overflow: visible !important;
            width: 100%;
            height: 300px ;
            top: -100px;
            left: 0px;
        }
        }
    }

    &.personalis-bg {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: visible;
        &::before {
            @media (min-width: 992px) {
            content: '';
            position: absolute;
            background-image: url('../../../assets/images/cloud2.png'), url('../../../assets/images/cloud2.png');
            background-size: 24%, 18%;
            background-repeat: no-repeat;
            background-position: left -8rem top 50%, right 0rem top 1rem;
            overflow: visible !important;
            width: 100%;
            height: 100% ;
            top: -48px;
            left: 0px;
        }

        @media (min-width: 1200px) {
            content: '';
            position: absolute;
            background-image: url('../../../assets/images/cloud2.png'), url('../../../assets/images/cloud2.png');
            background-size: 24%, 18%;
            background-repeat: no-repeat;
            background-position: left -8rem top 50%, right 0rem top 1rem;
            overflow: visible !important;
            width: 100%;
            height: 100% ;
            top: -48px;
            left: 0px;
        }

        @media (min-width: 1400px) {
            content: '';
            position: absolute;
            background-image: url('../../../assets/images/cloud2.png'), url('../../../assets/images/cloud2.png');
            background-size: 24%, 18%;
            background-repeat: no-repeat;
            background-position: left -9.5rem top 60%, right 0rem top 1rem;
            overflow: visible !important;
            width: 100%;
            height: 100% ;
            top: -60px;
            left: 0px;
        }
        }
    }

    .home {
        &.animation-1 {
            background-image: url('../../../assets//images/Bird1_01_Flyin-Once-with-fade-in.gif');
            background-size: 18rem;
            background-repeat: no-repeat;
            background-position: top -6.1rem left -.6rem;
            @media (min-width: 375px) {
                background-position: top -6rem left 2rem;
            }
            @media (min-width: 768px) {
                background-size: 76rem;
                background-image: url('../../../assets//images/Bird1_desktop_01_flyin\(Once\)-with-fade-in-GREEN.gif');
                background-position: top -5.188rem left -9.938rem;
            }
            @media (min-width: 992px) {
                background-size: 76rem;
                background-image: url('../../../assets//images/Bird1_desktop_01_flyin\(Once\)-with-fade-in-GREEN.gif');
                background-position: top -4.7rem left -7.125rem;
            }
            @media (min-width: 1200px) {
                background-image: url('../../../assets//images/Bird1_desktop_01_flyin\(Once\)-with-fade-in-GREEN.gif');
                background-size: 78rem;
                background-position: top -4.6rem left -5.8rem
            }
            @media (min-width: 1400px) {
                background-image: url('../../../assets/images/Bird1_desktop_01_flyin\(Once\)-with-fade-in-GREEN.gif');
                background-size: 92rem;
                background-position: top -6rem left -10.2rem
            }
        }

        &.animation-2 {
            background-image: url('../../../assets//images/Bird1_02_MainMovement.gif');
            background-size: 18rem;
            background-repeat: no-repeat;
            background-position: top -6.1rem left -0.5rem;
            @media (min-width: 375px) {
                background-position: top -6rem left 2rem;
            }
            @media (min-width: 768px) {
                background-position: top -5.1rem left 22.5rem;
            }
            @media (min-width: 992px) {
                background-position: top -4.6rem left 25.2rem;
            }
            @media (min-width: 1200px) {
                background-position: top -4.3rem left 28rem;
            }
            @media (min-width: 1400px) {
                background-size: 21rem;
                background-position: top -5.5rem left 30rem
            }
        }

        &.animation-3 {
            background-image: url('../../../assets//images/Bird1_03_loop.gif');
            background-size: 18rem;
            background-repeat: no-repeat;
            background-position: top -6.1rem left -0.5rem;
            @media (min-width: 375px) {
                background-position: top -6rem left 2rem;
            }
            @media (min-width: 768px) {
                background-position: top -5.1rem left 22.5rem;
            }
            @media (min-width: 992px) {
                background-position: top -4.6rem left 25.2rem;
            }
            @media (min-width: 1200px) {
                background-position: top -4.3rem left 28rem;
            }
            @media (min-width: 1400px) {
                background-size: 21rem;
                background-position: top -5.5rem left 30rem

            }
        }
    }

    .shopping {
        position: relative;
        overflow: visible;
        .animation-1 {
            position: absolute;
            background-image: url('./../../../assets/images/Bird2_01_Flying.gif');
            background-size: 22rem;
            height: 250px;
            width: 100%;
            background-repeat: no-repeat;
            top: -188px;
            right: -18px;
            background-position: top -5rem right 1rem;
            @media (min-width: 768px) {
                display: none;
            }
        }
        .animation-2 {
            position: absolute;
            background-image: url('./../../../assets/images/Bird_02_MainMovement.gif');
            background-size: 22rem;
            height: 250px;
            width: 100%;
            top: -188px;
            right: -18px;
            background-repeat: no-repeat;
            background-position: top -5rem right 1rem;
            @media (min-width: 768px) {
                display: none;
            }

            // background-position: top 3rem right 1.1rem;
        }

        .desktop {
            @media (min-width: 768px) {
                position: absolute;
                display: block;
                z-index: 1000;
                background-size: 7rem;
                background-image: url('./../../../assets/images/BirdD_03_loop_reverse.gif');
                background-position: bottom -1.35rem left 9.5rem;
                background-repeat: no-repeat;
                bottom: 0px;
                height: 120px;
                width: 100%;
            }
            @media (min-width: 1400px) {
                background-position: bottom -1.6rem left 12rem;
                background-size: 8rem;
            }
        }
     }

    .personalise {
        position: relative;
        overflow: visible;
        width: 100%;
        &.animation-1 {
            background-image: url('.//../../../assets/images/Bird3.1_01_flying.gif');
            background-size: 18rem;
            background-repeat: no-repeat;
            background-position: left 1rem top -9.4rem;
            z-index: 1000;
            @media (min-width: 340px) {
                background-position: left 4.4rem top -8.7rem;
            }
            @media (min-width: 768px) {
                background-image: none;
            }
        }

        &.animation-2 {
            background-image: url('.//../../../assets/images/Bird3.1_02_MainMovement.gif');
            background-size: 18rem;
            background-repeat: no-repeat;
            background-position: left 1rem top -9.4rem;
            z-index: 1000;
            @media (min-width: 340px) {
                background-position: left 4.4rem top -8.7rem;
            }
            @media (min-width: 768px) {
                background-image: none;
            }
        }

        &.animation-3 {
            background-image: url('./../../../assets/images/Bird3.1_03_loop.gif');
            background-size: 18rem;
            background-repeat: no-repeat;
            background-position: left 1rem top -9.4rem;
            z-index: 1000;
            @media (min-width: 340px) {
                background-position: left 4.4rem top -8.7rem;
            }
            @media (min-width: 768px) {
                background-image: none;
            }
        }
     }

    .box {
      min-height: 235px;
      max-width: 334px;
      border-radius: 32px;
      overflow: visible;
      object-fit: cover;
      margin: auto;
      position: relative;
      margin-bottom: 2rem;
      @media (min-width: 992px) {
        margin-left: 48px;
        margin-bottom: 0rem;
      }
      @media (min-width: 1400px) {
        margin-left: 7.5rem;
        max-width: 450px;
        max-height: 401px;
        margin-bottom: 0rem;
      }
      .wrap-img {
        overflow: hidden;
        border-radius: 2rem;
      }

      img {
        width: 100%;
        height: auto;
      }

      .p-desktop {
        @media (min-width: 768px) {
            position: absolute;
            height: 120px;
            width: 120px;
            left: 50%;
            top: -86px;
            // transform: translateX(-50%);
            background-size: 7rem;
            background-repeat: no-repeat;
            background-image: url('../../../assets/images/BirdD_03_loop_reverse.gif');
        }

        @media (min-width: 1400px) {
            background-size: 8rem;
            top: -97px;
        }
      }
    }

    .top-nav {
        height: 56px;
        padding: 0px 18.24px;
        span {
            cursor: pointer;
            @media (min-width: 768px) {
                margin-right: 1rem;
            }
            @media (min-width: 992px) {
                margin-right: 2rem;
            }
            @media (min-width: 1200px) {
                margin-right: 3.75rem;
            }
        }
        @media (min-width: 768px) {
            min-height: 56px ;
            padding-top: 60px;
        }
        img {
            margin-left: 12.25px;
            @media (min-width: 768px) {
                margin-left: 20px;
                width: 25px;
            }
        }
    }

    .content-2 {
        overflow: visible;
        position: relative;
        padding: 22px;
        color: $secondary ;
        width: 100%;
        display: flex;
        flex-direction: column;
        @media (min-width: 768px) {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin: auto;
            width: 90%;
            margin-top: 74px;
            margin-bottom: 74px;
        }
        @media (min-width: 1200px) {
            width: 80%;
            margin: auto;
            justify-content: center;
            margin-top: 100px;
            margin-bottom: 100px;
        }
        @media (min-width: 1400px) {
            width: 68.313rem;
            margin: auto;
            margin-top: 100px;
            margin-bottom: 100px;
        }
    }

    .content {
        overflow: visible;
        position: relative;
        padding: 22px;
        color: $secondary ;
        width: 100%;
        min-height: auto;
        @media (min-width: 768px) {
            display: flex;
            flex-direction: column;
            margin: auto;
            width: 90%;
        }
        @media (min-width: 992px) {
            margin: auto;
            width: 80%;
        }
        @media (min-width: 1200px) {
          width: 80%;
          margin: auto;
        }
        @media (min-width: 1400px) {
            width: 68.313rem;
            margin: auto;
        }
    }

    .info-col {
        min-width: 45%;
        @media (min-width: 768px) {
            min-width: 40%;
        }
        @media (min-width: 1200px) {
          min-width: 45%;
        }
      }

    .p-x-22{
        padding-left: 22px !important;
        padding-right: 22px !important;
    }

    .m-t-64 {
        margin-top: 0px;
        @media (min-width: 768px) {
            margin-top: 2rem;
        }
    }

    .p-b-20 {
        padding-bottom: 12px;
        @media (min-width: 340px) {
            padding-bottom: 16px;
        }
        @media (min-width: 375px) {
            padding-bottom: 20px;
        }
        @media (min-width: 768px) {
            padding-bottom: 4rem;
        }
    }

    .caption {
        font-size: 30px;
        line-height: 30px;
        height: 130px;
        font-family: $font-800;
        @media (min-width: 340px) {
            font-size: 34px;
            line-height: 34px;
            height: 180px;
        }
        @media (min-width: 360px) {
            font-size: 36px;
            line-height: 40px;
        }
        @media (min-width: 768px) {
            font-size: 40px;
            line-height: 40px;
            height: 128px;
        }
        @media (min-width: 992px) {
            font-size: 48px;
            line-height: 48px;
        }

        @media (min-width: 1200px) {
            font-size: 54px;
            line-height: 54px;
        }

        @media (min-width: 1400px) {
            font-size: 64px;
            line-height: 64px;
        }

        &.margin-top-30 {
            margin-top: 30px;
        }
        span {
            display: block;
            @media (min-width: 768px) {
                display: inline;
            }
        }
    }

    .title {
        font-size:52px;
        line-height: 60px;
        font-family: $font-900;
        text-transform: uppercase;
        @media (min-width: 375px) {
            font-size: 64px;
            line-height: 64px;
        }
        @media (min-width: 768px) {
            font-size: 76px;
            line-height: 76px;
            word-spacing: normal;
            flex-wrap: wrap;
        }
        @media (min-width: 992px) {
            font-size: 82px;
            line-height: 82px;
        }
        @media (min-width: 1200px) {
            font-size: 88px;
            line-height: 88px;
        }
        @media (min-width: 1400px) {
            font-size: 96px;
            line-height: 96px;
            margin-top: 2rem;
        }
    }

    .sub-title {
        font-size: 32px;
        line-height: 32px;
        padding-top: 28px;
        padding-bottom: 1rem;
        font-family: $font-800;
        @media (min-width: 340px) {
            font-size: 40px;
            line-height: 40px;
        }
        @media (min-width: 992px) {
            font-size: 44px;
            line-height: 44px;
        }
        &.margin-top-30 {
            margin-top: 36px;
        }
        span {
            display: block;
            @media (min-width: 992px) {
                display: inline;
            }
        }
    }

}


// 5 break points
//

// Discover Section

.discover {
    overflow: visible;
    position: relative;
    padding: 0px;
    color: $secondary ;
    width: 100%;
    display: flex;
    flex-direction: column;
    @media (min-width: 768px) {

    }

    .full-width-container {
        padding-left: 0px;
        padding-right: 0px;
    }

    .discover-card {
        width: 100%;
        max-width: 100%;
        margin: auto;
        margin-bottom: 16px;
        @media (min-width: 768px) {
            max-width: 80.42%;
            margin-bottom: 64px;
        }

        .container {
            padding-left: 21px;
            padding-right: 21px;
            @media (min-width: 768px) {
                padding: 0px;
            }

            .listing-card {
                margin-bottom: 1rem;
            }
        }
        .section-bg {
            box-shadow:0 4px 8px 0 rgba(0, 0, 0, 0.2) !important;
            max-height: 700px !important;
            height: 578px !important;
            padding: 1rem;
            @media (min-width: 768px) {
                height: 613px !important;
            }

            @media (min-width: 992px) {
                height: 622px !important;
            }
        }

        .carousel-title {
            padding-left: 0px;
            font-size: 1.3rem;
            @media (min-width: 360px) {
                font-size: 1.5rem;
            }

            @media (min-width: 768px) {
                padding-left: 60px;
            }
        }

        .carousale-tracking {

            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            span {
                display: inline;
            }
            @media (min-width: 768px) {
                padding-left: 60px;
                font-size: 16px;
                line-height: 32px;
            }
        }

        .carousel-arrow {
            right: 0px;
        }

        .carousel-arrow-left {
            left: 0px;
        }

        .listing-carousel {
            padding: 0px;
            @media (min-width: 768px) {
                padding: 0px 60px;
            }
        }

        .section-group {
            margin-bottom: 0px;
        }

        .section-group-body {
            margin-top: calc(-582px + 45px) !important;
            height: calc(582px - 45px) !important;
            @media (min-width: 375px) {
                margin-top: calc(-586px + 49px) !important;
                height: calc(586px - 49px) !important;
            }

            @media (min-width: 768px) {
                margin-top: calc(-601px + 52px) !important;
                height: calc(601px - 52px) !important;
            }

            @media (min-width: 992px) {
                margin-top: calc(-622px + 65px) !important;
                height: calc(622px - 65px) !important;
            }
        }
    }

    .paragraph {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        padding-left: 22px;
        padding-right: 22px;
        padding-bottom: 0rem;
        @media (min-width: 768px) {
            padding-bottom: 30px;
            max-width: 100%;
            margin: auto;
        }
        @media (min-width: 1200px) {
            padding-bottom: 30px;
            font-size: 24px;
            line-height: 32px;
            margin: auto;
          }

    }

    .sub-title {
        font-size: 32px;
        line-height: 32px;
        padding-top: 28px;
        padding-bottom: 1rem;
        font-family: $font-900;
        @media (min-width: 360px) {
            font-size: 36px;
            line-height: 36px;
        }
        @media (min-width: 768px) {
            text-align: center;
        }
        @media (min-width: 992px) {
            font-size: 44px;
            line-height: 44px;
            text-align: center;
        }

        span {
            display: block;
            @media (min-width: 992px) {
                display: inline;
            }
        }
    }
 }