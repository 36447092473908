@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');

@font-face {
    font-family: 'ABC Monument Grotesk';
    src: url('../fonts/ABCMonumentGrotesk-Regular.woff2') format('woff2'),
         url('../fonts/ABCMonumentGrotesk-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'ABC Monument Grotesk Bold';
    src: url('../fonts/ABCMonumentGrotesk-Bold.woff2') format('woff2'),
         url('../fonts/ABCMonumentGrotesk-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'ABC Monument Grotesk Medium';
    src: url('../fonts/ABCMonumentGrotesk-Medium.woff2') format('woff2'),
         url('../fonts/ABCMonumentGrotesk-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'ABC Monument Grotesk Heavy';
    src: url('../fonts/ABCMonumentGrotesk-Heavy.woff2') format('woff2'),
         url('../fonts/ABCMonumentGrotesk-Heavy.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'ABC Monument Grotesk Black';
    src: url('../fonts/ABCMonumentGrotesk-Black.woff2') format('woff2'),
         url('../fonts/ABCMonumentGrotesk-Black.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

$primary-font: 'ABC Monument Grotesk', sans-serif;
$font-600: 'ABC Monument Grotesk Bold', sans-serif;
$font-700: 'ABC Monument Grotesk Medium', sans-serif;
$font-800: 'ABC Monument Grotesk Heavy', sans-serif;
$font-900: 'ABC Monument Grotesk Black', sans-serif;

$main: #DCFAD2;
$pr-100: #DCFAD2; // Primary
$secondary: #00322E;

$black: #333333; // 800
$black-900: #1A1A1A;
$black-600: #666666;
$black-500: #808080;
$black-300: #B3B3B3;
$black-100: #E5E5E5;
$black-50: #F0F3F0;
$opaque-black: rgba(0, 0, 0, 0.6);
$black-10: #00000099;

$white: #FFFFFF;

$main-600: #128556;
$main-700: #0E6440;
$main-100: #DCFAD2;
$main-50: #EEFDE9;

$pr-700: #16733B;
$pr-500: #239F55;
$pr-200: #B1FAA0;
$pr-60: #EDFCE8;
$pr-50: #EEFDE9;

$se-800: #001412;
$se-400: #335B58;
$se-50: #E5EBEA;

$peach: #FFBEB4;
$peach-500: #FEF9F3;
$peach-700: #FEF4F4;
$peach-50: #FEF4F4;
$g-700: #16733B;
$psu-100: #FFF8F6; // price shift up

$main-inactive: grey;
$red: #ff0000;
$navy: #021235;
$green: #32f21b;
$green-200: #239F55;
$bright-blue: #1e58d7;

$yellow: #f4ac1a;
$purple: #554cff;
$pink: #e20076;
$peach-dark: #ffdccc;
$box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;

body {
  font-size: 16px;
  font-family: $primary-font;
  -webkit-font-smoothing: antialiased;
  color: $se-800;
  padding-top: 56px;
  line-height: 1.5em;
  overflow-x: hidden;
  @media (min-width: 1200px) {
    padding-top: 88px;
  }
}

@media (max-width: 767px){
  .d-close{
    display: none !important;
  }
}

@media (min-width: 768px){
.d-lg-none {
    display: none !important;
  }
}

p {
  line-height: 24px;
  white-space: pre-wrap;
}
a {
  color: $se-800;
  &:hover {
    color: $se-800;
  }
}

.input-group-prepend {
  height: 35px;
}

// Remove arrows from number input field
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* lb text colors */
.lb-secondary-text {
  color: $secondary;
}
.lb-budgee-text {
  color: $pr-500;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-time-container > .react-datepicker-time__input-container >  .react-datepicker-time__input input[type="time"] {
  width: auto;
}

.err-msg {
  color: red;
  font-size: 12px;
}

.hidden {
  display: none;
}

.vr {
  width: 1px;
  min-height: 1em;
  border-right: 1px solid #000000;
}

.lb-indicator {
  margin: 14px auto;
  width: 40px;
  height: 5px;
  background: $black-100;
  border-radius: 8px;
}



.bg-black { background-color: #01213f;}
.bg-main { background-color: $main !important;}
.bg-blue { background: $secondary; }
.bg-dark { background: #393939 !important;}
.bg-peach { background: $peach; }
.bg-grey {
  background: rgb(247, 247, 247);
}
.bg-dark-peach { background: $peach-dark;}

.footer {
  position: relative;
  z-index: 100;
  background-color: #fafafa;
  .footer-app-download {
    padding: 3em 0 0 0;
    .row {
      padding: 15px 35px;
      background-color: $pr-60;
      @media (min-width: 374.99px) {
        padding: 15px 66px;
      }
      @media (min-width: 768px) {
        padding: 22px 66px;
      }
      .first {
        justify-content: center;
      }
      .title {
        line-height: 18px; /* 112.5% */
        font-size: 16px;
        font-family: $font-600;
        margin: auto 0px;
        @media (max-width: 575.99px) {
          max-width: 170px;
        }
        @media (min-width: 768px) {
          font-size: 24px;
          line-height: 22px;
        }
        @media (min-width: 1200px) {
          font-size: 38px;
          line-height: 36px;
          margin: auto 0px;
          text-align: center;
        }
      }
      .download-app-store {
        width: 126px;
      }
      .download-app-qr-code {
        width: 63px;
        margin-left: 47px;
      }
      .download-app-text {
        padding-left: 12px;
        font-size: 18px;
        font-style: normal;
        line-height: 115%;
        margin: auto 0px;
      }
    }
  }
  .footer-container {
    .container {
      @media (min-width: 992px) {
        padding-left: 3em;
        padding-right: 3em;
      }
      .disclaimer {
          color: #001412;
          font-size: 14px;
          padding-top: 24px;
          .disclaimer-heading {
            font-weight: 700;
          }
      }


    }

    .footer-brand {
      .logo {
        justify-content: center;
        align-items: center;
        padding: 6px 19px;
        @media (min-width: 374.99px) {
          padding: 6px 50px;
        }
        @media (max-width: 375.1px) {
          justify-content: flex-start;
        }
        .lb-logo {
          position: relative;
          padding-right: 24.94px;
          margin-right: 26.18px;
          img {
            width: 84.48px;
            @media (min-width: 768px) {
              width: 150px;
            }
          }
          &::after {
            content: '';
            width: 1px;
            height: 100%;
            background-color: #999;
            display: inline-block;
            position: absolute;
            right: 0;

          }
        }
        .partner-logo img {
          width: 141px;
          @media (min-width: 768px) {
            width: 226px;
          }
        }

        @media (min-width: 768px) {
          justify-content: start;
        }
      }
      .social-actions {
        img {
          height: 23px;
          width: 23px;
        }
        font-size: 18px;
        font-style: normal;
        line-height: 19.592px;
        span {
          font-family: $font-700;
        }
      }
    }
    .social-actions-mobile {
      font-size: 12px;
      font-style: normal;
      line-height: 18px;
      .strong {
        font-family: $font-700;
      }
      img {
        height: 35px;
        width: 35px;
      }
    }
  }
  .nav-link {
    padding: 0;
  }
  .copyright {
    border-top: 1px solid rgba(26,26,26,0.1);
    font-size: 14px;
    padding-top: 24px;
    padding-bottom: 24px;
    color: $se-400;
  }
  h4 {
    margin-bottom: 1em;
    margin-top: 1em;
  }
  @media (max-width: 767.98px) {
    ul {
      columns: 2;
      -webkit-columns: 2;
      -moz-columns: 2;
    }
  }
  @media (max-width: 991.98px) {
    .quicklinks {
      columns: 2;
      -webkit-columns: 2;
      -moz-columns: 2;
    }
  }

  .auth-action {
    a {
      display: inline-flex;
      align-items: center;
      width: fit-content;
      padding: 4px 8px;
      font-size: 12px;
      line-height: 16px;
      border-radius: 24px;
    }
    &.auth-action-mobile {
      a:first-child {
        margin-right: 20px;
      }
    }
    &.auth-action-desktop-login {
      margin-top: 1em;
    }
    &.auth-action-desktop-personalise {
      margin-top: 20px;
      margin-bottom: 24px;
    }
  }

}


img.border {
  border: 1px solid rgba(151, 151, 151, 0.5);
}

/* Profile Images */
.profile-img {
  width: 96px;
  height: 96px;
  border: 1px solid $black-500;
  border-radius: 5em;
  &.cover {
    object-fit: cover;
  }
}
.profile-img-md {
  width: 47px;
  height: 47px;
}
.profile-img-xs {
  width: 24px;
  height: 24px;
}
.profile-img-sm {
  width: 35px;
  height: 35px;
}
.profile-img-lg {
  width: 96px;
  height: 96px;
  object-fit: cover;
  @media (min-width: 576px) {
    width: 144px;
    height: 144px;
  }
}


// @media (min-width: 1200px) {
//   .sidebar {
//     width: 260px;
//     max-width: 310px;
//     min-width: 230px;
//   }
// }
@media (min-width: 1200px) and (max-width: 1440px) {
  .main-content {
    padding-left: 1em;
  }
}
@media (max-width: 1199.98px) {
  .main-wrapper {
    margin: 0;
  }
  .sidebar, .main-content {
    padding-left: 0;
    padding-right: 0;
  }
}
.row {
  margin-right: -0.5em;
  margin-left: -0.5em;
}
.no-gutters {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
  padding-left: 0.5em;
  padding-right: 0.5em;
}


.tooltip .tooltip-inner {
  background: black;
  color: white;
  font-size: 12px;
}
.popover .popover-body {
  max-height: 200px;
  overflow: scroll;
  padding: 15px 20px;
}

.errorBg {
  background-color: rgb(243, 244, 243);
  border-radius: 2em;
  height: 200px;
  .errorMessage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%
  }
}

.builder-component {
  .container {
    @media (max-width: 768px) {
    padding-left: 0px;
    padding-right: 0px;
    }
  }
  .container-fluid {
    @media (max-width: 768px) {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}

.builder-container {
  &.container-fluid {
    @media (max-width: 768px) {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}
