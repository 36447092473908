.for-you {
  color: $pr-500;
  font-family: $font-700;
  font-size: 12px;
  line-height: 16px;
  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 24px;
  }
  .heart-icons {
    height: 20px;
    width: 20px;
    background-image: url('../../images/icons/icn_personalised_feed_green_fill.svg');
    background-repeat: no-repeat;
    background-size: contain;
    @media (min-width: 768px) {
      height: 30px;
      width: 30px;
      background-size: contain;
    }
  }
}