.listing-group-carousel {
  &.trending-group-carousel {
    max-width: 1504px;
    margin-left: -32px;
  }
}
.listing-carousel {
  &.trending-carousel {
    margin-top: -42px;
    max-width: 1600px;
    @media  (max-width: 767px) {
      overflow: scroll;
    }
  }
}
.wrap {
  display: flex;
  @media  (max-width: 767px) {
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;
    height: 122px;
  }
}
.box {
  flex-shrink: 0;
  text-align: center;
}

.trend-pill {
  background: $black-50;
  border-radius: 30px;
  display: block;
  height: max-content;
  margin-right: 0.625em;
  padding: 0.75em 1em;
  min-width: max-content;
  color: $secondary;
  margin-bottom: 0.7em;
  text-align: center;
  white-space: nowrap;
}
