.listing-card {
  padding-top: 0.5rem;
  /* Coupons */
  .card-coupon {
    width: 250px;
    height: 335px;
    &.exclusive {
      outline: 2px solid $peach;
      overflow: hidden;
      .card-footer {
        .card-meta-info {
          justify-content: space-between;
        }
      }
    }
    .card-img-top {
      padding: 0;
      align-items: center !important;
      justify-content: center !important;
      img {
        width: 100%;
        border-radius: 0;
      }
    }
    .card-body {
      padding: 0;
      .coupon-title-info {
        color: #001412;
        height: 65px;
        padding: 10px 10px 0;


        font-size: 14px;
        line-height: 16px;
        .coupon-store {
          font-family: "ABC Monument Grotesk Bold", sans-serif;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        .card-text {
          font-weight: 400;
          -webkit-line-clamp: 2;
          height: 32px;
        }
      }
      .coupon-offer-info {
        color: $secondary;
        height: 50px;
        padding: 0 10px;
        .coupon-type {
          font-family: "ABC Monument Grotesk Heavy", sans-serif;
          white-space: nowrap;
          font-weight: 800;
          font-size: 24px;
          line-height: 32px;
          height: 32px;
          text-transform: lowercase;
          &::first-letter {
            text-transform: uppercase;
          }
        }
        .end-date-info {
          padding: 0;
          font-size: 12px;
          line-height: 16px;
        }
        .view-count {
          margin: 0;
        }
      }
    }
    .card-meta-info {
      background-color: #F5F7FF;
      padding: 0 10px;
      .exclusive-badge {
        img {
          margin-right: 5px;
        }
        color: #00312E;
        background-color: #FADAD9;
        padding: 4px 10px;
        border-radius: 8px;
        line-height: 16px;
      }
      .sponsored-badge {
        img {
          margin-right: 5px;
        }
        color: $secondary;
        background-color: $white;
        padding: 4px 10px;
        border-radius: 8px;
        line-height: 16px;
      }
    }
  }
  .coupon-store {
    margin-bottom: 0;
  }
  .member-box-large-image {
    display: block !important;
    padding: 0em !important;
    align-items: flex-start;
    justify-content: space-between;
    text-align: center !important;
  }
  &.coupon-percentage {
    border-top-color: $purple;
  }
  &.coupon-value {
    border-top-color: $yellow;
  }
  &.coupon-free-shipping {
    border-top-color: $pink;
  }
  &.coupon-free-gift {
    border-top-color: #69b42a;
  }
  &.coupon-special-offer {
    border-top-color: #b4512a;
  }
}

.top-features {
  @media (max-width: 991.98px) {
    .card-coupon {
      height: 282px;
      .coupon-btn {
        height: 44px;
        line-height: 44px;
      }
    }
  }
}

.listing-grid {
  .card-coupon {
    width: inherit;
    height: inherit;
    .card-img-top {
      height: 246px;
    }
    .card-body {
      .coupon-title-info {
        font-size: 18px;
        line-height: 20px;
        height: 80px;
        padding: 10px 15px 0;
        .card-text {
          font-size: 18px;
        }
      }
      .coupon-offer-info {
        height: 74px;
        padding: 10px 15px 0;
        .coupon-type {
          font-size: 32px;
        }
        .end-date-info {
          font-size: 14px;
          line-height: 20px;
        }
        .view-count {
          img {
              width: 19px;
              height: 14px;
          }
          small {
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }
    .card-footer {
      height: 54px;
      .card-meta-info {
        font-size: 14px;
        padding: 0 15px;
      }
    }
  }
}