.status-label {
  padding: 2px 5px;
  font-weight: bold;
  display: inline-block;
  color: white;
  border-radius: 2px;
  font-size: 80%;

  &.archived {
    background-color: rgb(87, 87, 87);
  }

  &.published {
    background-color: rgb(12, 126, 12);
  }

  &.pending {
    background-color: rgb(234, 234, 83);
  }

  &.rejected {
    background-color: rgb(151, 33, 33);
  }

  &.expired {
    background-color: rgb(151, 33, 33);
  }
}
