@import './global';
@import './content/content';
@import './components/components';
@import './components/cards/cards';

@import './layouts/layouts';


.container {
  padding-left: 1rem;
  padding-right: 1rem;
  max-width: inherit;
  @media (min-width: 1500px) {
    max-width: 1600px;
  }
}