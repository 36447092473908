body {
    background-color: #fafafa !important;
}

.rails-default-error-page {
    /* background-color: #FFFFFF; */
    color: #2E2F30;
    text-align: center;
    margin: 0;
}

.page-container {
    width: 30%;
    margin: 5% auto 0;
}

.logo {
    width: 50%;
}

.page-container .img {
    width: 100%;
}

.p-bottom-16 {
    padding-bottom: 16px
}

@media (max-width: 767.98px) {
    .page-container {
      width: 65%;
    }
}
