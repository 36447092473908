a.link-wrapper {
  color: inherit;
  text-decoration: inherit;
}

button.btn-wrapper {
  margin: 0;
  padding: 0;
  &.active {
    outline: none;
  }
}
