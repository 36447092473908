.listing-card {
  @media (max-width: 575px) {
    margin-bottom: 0.5em;
  }
  /* Price Drop */
  .brand-logo img {
    max-height: 38px;
    max-width: 38px;
    width: 38px;
    height: 38px;
    object-fit: contain;
    position: absolute;
    bottom: 5px;
    left: 10px;
    border-radius: 50%;
    border: 1px solid lightgrey;
    background-color: white;
  }
  .brand-logo-stack {
    position: relative;
    width: 68px;
    height: 48px;
    img {
      position: absolute;
      max-height: 48px;
      max-width: 48px;
      width: 48px;
      height: 48px;
      object-fit: contain;
      border: 1px solid $black-300;
      border-radius: 50em;
      background-color: #ffffff;
    }
    img:nth-child(1) {
      z-index: 20;
    }
    img:nth-child(2) {
      position: absolute;
      left: 10px;
      z-index: 10;
    }
    img:nth-child(3) {
      left: 20px;
    }
  }
  .stores-count {
    small {
      font-family: $font-600;
      font-size: 12px !important;
      color: $se-800;
      margin-left: 7px;
    }
  }
  .card-price-drop {
    min-width: 250px;
    height: 335px;
    @media (min-width: 992px) {
      height: 335px;
      width: 250px;
    }
    .card-body {
      .card-title {
        height: 50px;
        margin-bottom: 0px;
        border-top: 1px solid rgba(0, 0, 0, 0.1)
      }
      .view-count {
        margin-top: -10px;
      }
    }
    .price-details {
      padding-left: 10px;
      .price-now {
        color: $secondary;
        margin-bottom: 0;
        margin-right: 0.25em;
        font-size: 24px;
        line-height: normal;
      }
    }
  }
  .price-was {
    font-size: 14px;
    color: $se-400;
  }
  .card-footer {
    height: 45px;
    .card-meta-info {
      font-size: 12px;
    }
  }
}
.mb-price-drops-2w {
  margin-top: -40px;
}

/* Ranking page */
.main-wrapper .main-content {
  .card-price-drop {
    width: inherit;
    margin: auto;
    @media (min-width:576px) {
      width: 250px;
    }
  }
}


/* Price drop label */
.price-drop-label {
  background: $main-100;
  border-radius: 0.5em;
  padding-left: 0.5em;
  padding-right: 1.75em;
  position: relative;
  height: 32px;
  width: fit-content;
  &:after {
    content: "";
    width: 24px;
    height: 24px;
    top: 4px;
    right: 0.25em;
    background: url('../../../../assets/images/icons/icn_price-drop.svg') no-repeat 50%;
    background-size: contain;
    position: absolute;
  }
  .pricedrop {
    color: $secondary;
    font-family: $font-600;
    padding-left: 4px;
  }
}


/** Price Drop **/
.price-drop-label-alt {
  border-radius: 0.5em;
  padding: 0.25em 0.5em;
  border: 1px solid black;
  height: 32px;
  font-family: $font-600;
  &:after {
    content: none;
  }
  &.shop-fast-wont-last {
    color:  #e99617;
    border-color:  #e99617;
  }
  &.get-in-fast {
    color: #179ae9;
    border-color: #179ae9;
  }
  &.dont-miss-out {
    color: #7317e9;
    border-color:  #7317e9;
  }
  &.best-price-now {
    color:  #720883;
    border-color:  #720883;
  }
}

/* Detail page */
.listing-detail {
  .price-was {
    color: $secondary;
    font-size: 21px;
  }
  @media (max-width: 475px) {
    .price-was {
      font-size: 14px;
    }
    .deal-price-now {
      font-size: 32px !important;
    }
    .deal-doller-symbol {
      font-size: 18px !important;
    }
    .info-icon {
      padding-bottom: 0.938rem !important;
      width: 13.5px !important;
    }
  }

  .deal-price-now {
    font-weight: 800;
    font-size: 48px;
    color: $secondary;
    font-family: 'ABC Monument Grotesk Heavy';
  }
  .deal-doller-symbol {
    font-size: 27px;
  }
  .info-icon {
    padding-bottom: 1.5rem;
    width: 20px;
    cursor: pointer;
  }
  .last-price-date {
    margin-left: 5px;
    font-weight: bold;

  }

  .deal-detail-price-drop {
    background: #EDFCE8;
    padding: 22px;
    border-radius: 12px;
    width: 165px;
    height: 72px;
    margin-left: 1.75em;
    @media (max-width: 425px) {
      margin-left: 0px;
      width: 132px;
      height: 49px;
      padding: 12px 6px;
    }
    .pricedrop {
      color: $secondary;
      font-family: $font-600;
      font-size: 28px;
      padding-left: 4px;
    }
    img {
      height: 30px;
      width: 40px;
    }
    &.deal-detail-price-up {
      background: #FFF8F6;
    }
  }
  .price-label-section-mobile {
    @media (max-width: 768px) {
      justify-content: space-between;
    }
  }
  .detail-view-count {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: -15px;
    &.icon-margin {
      margin-bottom: -50px;
    }
  }
  .detail-view-count-mobile {
    font-size: 12px;
    @media (min-width: 769px) {
      visibility: hidden;
    }
  }
  .price-history {
    margin-top: 5em;
    border-top: 1px solid $main-50;
    border-bottom: 1px solid $main-50;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    .card-body {
      padding: 2em;
      @media (max-width: 768px) {
        padding: 1em;
      }
    }
    @media (min-width: 768px) {
      border: 0;
      border-radius: 1em;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    }
  }
  .price-drop-notification {
    margin-top: 1em;
  }
  .detail-terms {
    margin-top: 1em;
  }

  .offer-comparison-container {
    .col {
      padding: 0;
    }
    .comparision-table-heading {
      padding: 0 1.1875em;
      background-color: transparent;
    }
    .card-body {
      padding: 1.1875em;
      .cmp-tbl-title {
        font-family: $font-800;
        font-size: 20px;
      }
      .profile-img-md {
        @media (max-width: 1199px) {
          width: 40px;
          height: 40px;
        }
      }

      .store-info {
        padding-left: 12px;

        @media (max-width: 767.98px) {
          padding-left: 8px;
        }

        .rating-meta {
          font-size: 12px;
          line-height: 24px;
          color: $se-800;
          font-weight: 800;
          .rating-meta-link {
            color: #0F78B2;
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
      .divider {
        width: calc(100% + 20px);
        height: 1px;
        border: 1px solid #F0F3F0;
        margin: 7px -10px;
      }
      .shipping-checkmark {
        filter: invert(28%) sepia(99%) saturate(1322%) hue-rotate(178deg) brightness(94%) contrast(88%)
      }
      .shipping-meta-link {
        color: #0F78B2;
        text-decoration: underline;
        font-size: 12px;
        cursor: pointer;
        display: block;
      }
      .shipping-view-more {
        display: flex;
        font-size: 12px;
        font-weight: bold;
        cursor: pointer;
        margin-left: 5px;
      }
      .cmp-goto-btn {
        min-width: 175px;
      }
    }
    .out-of-stock {
      font-size: 16px;
      font-weight: 500;
      color: #001412;
    }
    .view-graph-link {
      font-size: 16px;
      font-style: italic;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0px;
      text-align: left;
      color: #16733B;
      text-decoration: underline;
    }
    .graph-icon {
      width: 26px;
      height: 26px;
    }
    .price-comaprison-info {
      font-size: 12px;
      color: #000000;
      font-weight: 400;
      line-height: 16px;
      margin-bottom: 26px;
      img {
        width: 13.5px;
        height: 13.5px;
        margin-right: 3.5px;
        margin-top: 1px;
      }
    }
    .disabled {
      opacity: 0.4;
    }
    .comparision-table-symbol {
      font-size: 16px;
      margin-left: 2px;
    }
    .comparision-table-price-drop {
      width: 92px;
      height: 34px;
      background: #EDFCE8;
      border-radius: 8px;
      padding: 6px;
      &.comparision-table-price-drop-up {
        background: #FFF8F6;
      }
    }

    .comparision-table-price-drop img {
      width: 25px;
      height: 18.61px;
    }
    .cmp-price-was {
      font-size: 12px;
      color: $secondary;
    }
    .cmp-price-label {
      font-size: 24px;
      font-weight: 800;
      color: $secondary;
      font-family: 'ABC Monument Grotesk Heavy';
      & sup {
        top: -5px !important;
      }
    }
    .cmp-pricedrop {
      font-size: 18px;
      font-weight: 700;
      color: $secondary;
      padding-left: 4px;

    }
    .mobile-price-date {
      display: none;
    }
    .mobile-res-goto-store {
      max-width: 130px;
      @media (min-width: 768px){
        max-width: 175px;
      }
    }
    .btn {
     font-family: 'ABC Monument Grotesk Medium';
     font-weight: 500;
     float: right;
     @media (min-width: 768px){
      width: 175px;
     }
    }
    .cmp-table-info-icon {
      width: 20px;
      margin-top: -8px;
      margin-left: 4px;
      cursor: pointer;
    }
    @media (max-width: 425px){
      .btn {
        padding: 7px 12px;
        font-size: 12px;
        height: 32px;
        line-height: 16px;
        float: right;
      }
      .mobile-res-goto-store {
        max-width: 6.75rem;
      }
     .cmp-table-info-icon {
       width: 12px;
       margin-top: -10px
     }
     .mb-align {
      justify-content: space-between;
     }
    }
    @media (max-width:375px) {
      .last-price-date {
        display: none;
      }
      .mobile-price-date {
        display: block;
        font-weight: bold;
        font-size: 12px;
        margin-top: -9px;
      }
    }
    // for 320px
    @media (max-width: 320px) {
      .btn {
        padding:5px;
      }
      .comparision-table-price-drop {
        width: 62px;
        height: 32px;
      }
      .comparision-table-price-drop img {
        display: none;
      }
      .mobile-res-goto-store {
        max-width: 5.125rem;
      }
      .mobile-view {
        padding: 0px;
      }
    }
  }
  .best-price-tag {
    padding: 0.25em 0.5em;
    border-radius: 0.5em;
    background: #00322E;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 16px;
    height: max-content;
  }
  .offset {
    position: absolute;
    top: -15px;
    left: 20px;
    @media (min-width: 1200px) {
      left: 15px;
    }
  }
  .best-seller {
    border: 2px solid #00322E
  }
  .del{
    text-decoration: line-through;
  }
}
.lowest-price {
  background: #FFFFFF;
  border: 1px solid #F0F3F0;
  border-radius: 8px;
  padding: 4px 8px;
  margin-left: 12px;
  line-height: 12px;
  font-size: 12px;
  height: 34px;
  justify-content: center;
  span {
    font-family: $font-800;
  }
  p {
    line-height: 12px;
  }
}
.raise-hand-icon {
  margin-right: 6px;
  width: 24px;
  height: 24px;
}
.price-drop-row {
  background-color: #F0F3F0;
  border-radius: 21px;
  margin-top: 2em;
}
.cmp-shipping-meta-border {
  border-top: 2px solid #F0F3F0;
  margin: -10px;
  margin-top: 0.5rem;
  padding: 0 10px;
  padding-top: 1rem;
  min-width: calc(100% + 20px);
}
.notify-me {
  text-align: right;
}
@media (max-width: 992px) {
  .price-drop-row {
    border-radius: 0px;
    margin: 0 -1em 0 -1em;
  }
   .notify-me {
     text-align: left;
     margin: -1rem 0 1rem 0;
   }
}

.listing-grid {
  .listing-card {
    .card-price-drop {
      height: inherit;
      @media (min-width:576px) {
        height: 335px;
      }
      &.card-price-drop-2w {
        min-width: 145px;
      }
      .card-img-top {
        position: relative;
        height: 246px;
        padding: 10px;
        &.card-image-top-2w {
          height: 141px;
          padding: 5px;
        }
        .lazy-load-image-background {
          height: 100%;
          display: block !important;
        }
        .brand-logo img {
          max-height: 52px;
          max-width: 52px;
          width: 52px;
          height: 52px;
          left: 15px; 
        }
        .brand-logo-2w img {
          max-height: 38px;
          max-width: 38px;
          width: 38px;
          height: 38px;
          left: 10px; 
        }
      }
      .card-body {
        .deal-name {
          height: 80px;
          padding: 10px 15px 0px;
          .view-count {
            line-height: 16px;
            margin-top: -8px;
            small {
              font-size: 12px;
              @media (max-width: 575px) {
                font-size: 10px;
              }
            }
            img {
              width: 19px;
              height: 14px;
              @media (max-width: 575px) {
                width: 13px;
                height: 10px;
              }
            }
            @media (max-width: 575px) {
              line-height: 8px;
              margin-top: 4px;
            }
          }
          &.deal-name-2w {
            height: 55px;
            padding: 5px 10px 0px;
          }
        }
        .card-title {
          height: 74px;
          &.card-title-2w {
            height: 55px;
          }
        }
        .card-text {
          height: 60px;
          font-size: 18px;
          line-height: 20px;
          &.card-text-2w {
            height: 30px;
            font-size: 11px;
            line-height: 14px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
        .price-was {
          font-size: 14px;
          margin-top: 0px;
          line-height: 20px;
          &.price-was-2w {
            font-size: 11px;
            line-height: 12px;
          }
          .last-price-date-2w {
            display: none;
          }
        }
        .last-price-date-mb {
          font-size: 11px;
          line-height: 12px;
          font-weight: bold;
        }
        .doller-symbol {
          font-size: 18px;
          line-height: 24px;
          margin-right: 2px;
          &.doller-symbol-2w {
            font-size: 11px;
            line-height: 12px;
          }
        }
        .cents {
          font-family: $font-700;
          font-size: 18px;
          line-height: 24px;
          margin-left: 2px;
          &.cents-2w {
            font-size: 11px;
            line-height: 12px;
          }
        }
        .deal-price-drop-label {
          .pricedrop {
            font-size: 28px;
            line-height: 20px;
            &.pricedrop-2w {
              font-size: 10px;
              line-height: 15px;
            }
          }
          .price-drop-img {
            height: 45px;
            width: 45px;
            &.price-drop-img-2w {
              height: 18px;
              width: 18px;
            }
          }
        }
      }
      .price-details {
        padding-left: 15px;
        .price-now {
          margin-bottom: 0;
          margin-right: 0.25em;
          font-size: 32px;
          line-height: 32px;
          &.price-now-2w {
            font-size: 14px;
            line-height: 14px;
          }
        }
      }
      .card-footer {
        height: 54px;
        .card-meta-info {
          font-size: 14px;
          &.card-meta-info-2w {
            font-size: 11px;
            @media (max-width: 380px) {
              font-size: 9px;
              padding: 0px;
            }
          }
        }
        &.card-footer-2w {
          height: 35px;
        }
        .deal-store-nm {
          font-family: $font-600;
        }
      }

    }
  }
}