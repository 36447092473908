.goto-page-container-cba {
  @supports (font: -apple-system-body) {
    html {
      font: -apple-system-body !important;
    }
  }
  .loader {
    top: 37%;
    position: absolute;
    left: 44%;
  }
  padding: 0px 9% !important;
  margin-top: 14%;
  .tick-image-cba {
    height: 20px;
    width: 20px;
    padding: 2px;
    background-color:  #239F55;
    border-radius: 34px;
    margin-right: 5px;
    margin-bottom: 3px;
  }
  .fly-container-cba {
    position: relative;
    margin: 0px auto 30px;
    .fly-img-cba {
      width: 100%;
      margin: 1rem auto -35px;
    }
    .from-img-cba {
      width: 4rem;
      margin-left: 10px;

    }
    .to-img-cba {
      width: 4rem;
      border: 1px solid #D3D2D2;
      margin-right: 15px;
    }
    .code-copy-cba {
      color: $secondary;
      font-size: 18px;
      font-family: $font-700;
      text-align: center;
      position: absolute;
      top: 49%;
      left: calc(50% - 4.5rem);
      width: 9rem;
      p {
        margin-bottom: 21px;
      }
      &.code-copy-cba-sale-event {
        left: 50%;
        transform: translate(-50% , -50%);
        top: 75%;
      }
    }
    .store-name {
      font-size: 14px;
      color: #706D6E;;
      line-height: 16px;
      margin: auto;
    }
    .store-name-sale-event {
      font-size: 16px;
      color: $secondary;
      line-height: 19px;
    }
    .store-name-align{
      display: block;
    }
    @media (max-width: 767px) {
      .fly-container-cba {
        width: 100%;
      }
      .from-img-cba {
        width: 4rem;
        margin-left: 0;
      }
      .to-img-cba {
        width: 4rem;
        margin-right: 0;
      }
      .fly-img-cba {
        padding: 0px 10px;
        margin-bottom: -6%;
      }
    }

  }
  .goto-bottom-section {
    text-align: center;
    .powered-by {
      font-size: 14px;
      color: $se-800;
      margin-bottom: 0px;
    }
    .get-price {
      font-size: 14px;
      color: $secondary;
      font-family: $font-700;
      span {
        color: $green-200;
      }
    }
  }

}