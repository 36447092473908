// listing card used for newsletter and custom list
.listing-card {
  &.listing-collection {
    .card {
      height: 412px;
      width: 283px;
      @media (min-width: 992px) {
        height: 448px;
        width: 348px;
      }
    }
    .collection-tag {
      background: $pr-50;
      padding: 0.5em;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.5em;
      img {
        margin-right: 0.5em;
      }
    }
    .card-collection-image {
      height: 203px;
      width: 251px;
      margin-top: 0.5em;
      margin-bottom: 0.5em;
      border-radius: 0.5em;
      @media (min-width: 992px) {
        height: 256px;
        width: 316px;
      }
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 0.25em;
      }
    }
    h2, h3 {
      margin-bottom: 0;
    }
  }
}

.listing-grid {
  .card-collection {
    width: inherit !important;
    max-width: 348px;
    margin-left: auto;
    margin-right: auto;
    .card-collection-image {
      width: auto;
    }
  }
}