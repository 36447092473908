.isHomePage {
  @media (min-width: 1600px) {
    .top-50 .container-fluid, .home-banner, .top-50-bf .container-fluid {
      padding-left: 1em;
      padding-right: 1em;
    }
  }
  //for homepage store and brands cards padding
  .homepageBrandStore {
    padding-top: 10px;
    margin-bottom: -23px;
  }

  .listing-group-header {
    padding-left: 1em;
    padding-right: 1em;
    @media (min-width: 768px) {
      padding-left: 3.8em;
      padding-right: 3.8em;
    }
  }

  .listing-group-carousel .listing-card {
    transform: translateX(0);
    @media (min-width: 768px) {
      transform: translateX(0);
    }
  }

  .home-banner-container {
    background: $se-50;
    height: 244px;
    display: flex;
    align-items: center;
    border-radius: 2em;
    .message {
      padding-left: 2.5em;
      padding-right: 2.5em;

    }
  }

  @media (max-width: 767.98px) {
    .top-50 .container-fluid, .home-banner, .section-group .container, .top-50-bf .container-fluid{
      padding-left: 0;
      padding-right: 0;
    }
  }
  .advertisements-list-hero {
    .carousel-root {
      width: 100%;
    }
    .advert, .carousel-slider {
      z-index: 11;
    }
    .lazy-load-image-background {
      margin-bottom: -1px;
    }
  }
}

.listing-group-header {
  padding-left: 1em;
  padding-right: 1em;

  @media (min-width: 768px) {
    padding-left: 3.8em;
    padding-right: 3.8em;
  }
}

.non-footer-view {
  margin-bottom: 80px;
}
.lbredirect-modal {
  @media (max-width:576px){
    background-color: transparent !important;
  }
  .modal-content {
    align-items: center;
  }
}
.lb-redirect {
  position: absolute;
  top: 6.875rem;
    max-width: 336px;
  img {
    border-radius: 20px;
  }
}
.homepageheader {
  font-size: 1.75rem;
  font-family: $font-800;
  color: $secondary;
  line-height: 110%;
  margin-left: auto;
  margin-right: auto;
  padding: 1.5rem 1.5rem 0rem;
  p {
    font-size: 0.85rem;
    font-family: $primary-font;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: $se-800;
  }
  @media(min-width:374.98px) {
    font-size: 2rem;
    padding: 1.5rem 1.625rem 0rem;
    p {
      font-size: 1rem;
    }
  }
  @media (min-width: 768px) {
    font-size: 2.375rem;
    padding: 1.5rem 4.8rem 0rem;
    p {
      font-size: 1.25rem;
    }
  }
  @media (min-width: 991.99px) {
    font-size: 3rem;
    p {
      font-size: 1.5rem;
    }
  }
  @media(min-width:1500px) {
    max-width: 1600px;
  }
}
.bf-tag-small {
  width: 170px;
  margin-left: 60px;
  margin-bottom: 10px;
  @media (max-width: 767.98px) {
    margin-left: 15px;
  }
}
