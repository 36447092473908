/***** BREADCRUMBS *****/
.breadcrumbs {
  // margin-bottom: 2.5em;
  padding-bottom: 0 !important;
  padding-top: 0;
  nav.breadcrumb {
    margin-bottom: 0;
  }
  ol.breadcrumb {
    background: $black-50;
    display: inline-flex;
    margin-bottom: 0;
    border-radius: 0.5em;
  }
  .breadcrumb-item {
    text-decoration: none;
    a {
      color: $g-700 !important;
    }
    &.active {
      color: $se-400 !important;
    }
  }
}

.breadcrumbs {
  padding: 1rem !important;
  background: $black-50;
  border-radius: 0.5em;
  display: inline-block;
  margin-top: 0.5em;
  &:after {
    display: none;
  }
  @media (min-width: 992px) {
    margin-top: 1.5em;
  }
}
.breadcrumb-menu {
  @media (min-width: 768px) {
    display: none !important;
  }
  .breadcrumb-item {
    display: block;
    padding-left: 1em !important;
    padding-right: 1em;
  }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0;
  }
  .breadcrumb-item + .breadcrumb-item::before {
    display: none;
  }
}
.breadcrumb {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  background: url('../../../assets/images/icons/chevron-down.svg') no-repeat 100%;
  padding: 0;
  margin-bottom: 0;
  padding-right: 2em;
  @media (min-width: 768px) {
    background: none;
    padding-right: 0;
  }
}
.breadcrumb-item {
  display: inline;
  white-space: normal;
}