.card-activity {
  a, a strong {
    color: $pr-700;
    font-family: $primary-font;
    font-weight: 400;
  }
  .activity-row:last-child {
    border-bottom: 0 !important;
  }
  .text-muted {
    font-size: 14px;
    color: $se-400 !important;
  }
}