.card-store-brand {
  width: 15.625rem;
  height: 12.5rem;
  overflow: hidden;
  .card-img-top {
    height: 125px;
    img {
      padding: 8px 0px;
    }
  }
}

.card-img-top-list {
  height: 177px;
    width: auto;
}

.store-brand-footer {
  background-color: #FEF9F3;
  height: 45px;
  padding: 0.25em;
  font-size: 12px;
  white-space: pre-wrap;
  &.store-brand-footer-list {
    height: 54px;
    font-size: 14px;
  }
}
.store-brand-stats {
  font-size: 10px;
}
.store-brand-header {
  height: 30px;
  border-bottom: 1px solid #E5E5E5;
}
.card-store-brand-list {
  height: 17.188rem;
  width: auto;
  .store-brand-stats-list {
    font-size: 12px;
  }
  .store-brand-header-list {
    height: 45px;
  }
}

.store-brand-ranking {
  .card-store-brand-list {
    width: auto;
    overflow: hidden;
  }
  .card-title {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  @media (max-width: 1199.98px) {
    .mobile-filter, .sort-dropdown {
      margin-left: calc(50% + 0.5em);
    }
  }
}