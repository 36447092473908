.department-view-all-section {
    border: 1px solid $black-500;
    border-radius: 32px;
    padding: 20px 0;
    @media (min-width: 1199.99px) {
        border-radius: 36px;
        padding: 28px 30px;
    }
    .view-all-label {
        font-family: $font-800;
        font-size: 24px;
        line-height: 26px;
        @media (min-width: 1199.99px) {
            line-height: 32px;
        }
        img {
            margin: auto 16px;
        }
    }

    .view-btn-group {
        display: flex;
        justify-content: space-evenly;

        &.first {
            padding: 22px 0px 10px 0px;
        }
        // &.second {
        //     padding: 10px 0px 26px 0px;
        // }

        @media (min-width: 1199.99px) {
            // justify-content: space-around;
            &.first, &.second {
                padding: 0;
            }
        }

        .btn-main {
            font-size: 12px;
            padding: 0 12px;
            height: 32px;
            line-height: 29px;
            &:first-child {
                margin-right: 10px;
            }
            &:last-child {
                margin-left: 10px;
            }
            @media (min-width: 767.99px) {
                height: 48px;
                line-height: 45px;
                font-size: 16px;
                padding: 0 16px;
                margin: 0;
            }
        }
    }
}
