html, body {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Gleap Live Chat Css override due to browser extension */
.bb-feedback-button-icon {
  display: block !important;
}

.gleap-frame-container {
  left: unset;
  margin-bottom: unset;
  bottom: 81px !important;
  width: calc(100% - 40px) !important;
  border-radius: 16px !important;
}

@media only screen and (max-width: 450px) {
  .gleap-frame-container {
    bottom: 0px !important;
    width: 100vw !important;
    height: 100vh;
    min-height: 100vh;
    border-radius: 0 !important;
  }
  .gleap-frame-container-inner {
    height: 100vh !important;
  }
  .gleap-frame-container--survey {
    height: 100vh !important;
    margin-bottom: 0px;
  }
}



.close-description-container {
  display: flex;
  padding: 0 42px;
}
.close-description {
  margin: auto;
}
@media only screen and (max-width: 576px) {
  .top-container {
    margin-top: 54px;
  }
  .close-description {
    width: 393px;
  }
}

@media only screen and (min-width: 768px) {
  .top-container {
    margin-top: 72px;
  }
  .close-description {
    width: 640px;
  }
}


/* Force Update Modal */
.force-update-modal {
  background-color: #fff !important;
  margin: 0 !important;
  .modal-content {
    border: none;
  }
}