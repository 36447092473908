.section-tabs-wrapper {
  max-width: 100vw;
  overflow: scroll;
  margin-bottom: 1.75em;
}
.section-tabs {

  flex-wrap: nowrap;
  .nav-link {
    border-radius: 0;
    color: $black;
    text-align: center;
    line-height: 1.25em;
    padding-left: 2em;
    padding-right: 2em;
    white-space: nowrap;
    &.active {
      color: $black;
      background: transparent;
      font-weight: 600;
      border-bottom: 3px solid $main;

      button {
        font-weight: 600;
      }
    }
  }
}

.nav-tabs {
  .nav-link {
    border: none;
  }

  button {
    background: none;

    &:focus {
      outline: 0;
    }
  }
}