.modal.cmp-store-reviews {
  overflow-y: hidden;
}

.store-reviews {
  font-family: $font-700;
  color: $secondary;
  height: calc(100vh - 150px);
  overflow-y: auto;
  padding: 20px 0px;
  border-radius: 8px;

  .store-review-header {
    height: 100px;
    background: $black-50;
    border-radius: 0px;
    margin-top: -22px;
    position: sticky;
    top: -22px;
    z-index: 98;

    .tustpilot-icon {
      padding: 1.25rem 4.75rem;
    }
  }

  .store-review-body {
    padding: 0 12px 8px;
    .rating-star {
      margin: 10px 0 5px;
    }
    .reviews-text {
      font-size: 16px;
      color: $secondary;
    }
    .star-rating {
      padding-bottom: 1rem;
      // justify-content: center;

      .rating-count {
        color: $secondary;
        font-size: 16px;
        cursor: pointer;
        position: relative;
      }
      .addBeforeCls:before {
        content: '';
        display: block;
        right: 8px;
        position: absolute;
        bottom: 100%;
        top: 20px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 20px 20px 0 0;
        border-color: white transparent transparent transparent;
        filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.10));
        transform: rotate(180deg );
        @media (min-width: 1600px) {
          top: 40px;
        }
      }
    }
    .trustscore-info {
      margin-bottom: -8px;
      display: inline;
      margin-right: 4px;
      @media (max-width: 320px) {
        margin-right: 0px;
        font-size: 14px;
      }
    }
    .ts-info-desc {
      background: #FFFFFF;
      border-radius: 8px;
      color: #001412;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08), 0px 10px 16px -4px rgba(0, 0, 0, 0.1);
      padding: 12px;
      margin-bottom: 25px;
      margin-right: 40px;
      @media (max-width: 475px) {
          margin-right: 20px;
        }
    }
    .ts-info {
      padding: 12px 25px;
    }
    .ts-title {
      font-size: 20px;
      line-height: 28px;
      font-family: $font-800;
    }
    .ts-desc {
      font-size: 12px;
      line-height: 14px;
    }
    @media (min-width: 768px) {
      padding: 30px 60px 60px;
      .star-rating {
        padding-left: 165px;
        justify-content: start;
      }
      .reviews-text,
      .rating-star,
      .rating-count {
        margin-right: 20px;
      }
      .reviews-text {
        font-size: 32px;
        margin-right: 20px;
        display: block;
      }
    }
    @media (min-width: 1140px) {
      .reviews-text,
      .rating-count,
      .rating-star {
        display: block;
      }
    }
    @media (min-width: 1600px) {
      .reviews-text,
      .rating-count,
      .rating-star {
        display: inline-block;
        // margin: 0;
      }
      .trustscore-info {
        text-align: left;
        display: block;
      }
    }
  }

  .profile-img-w {
    width: 68px;
    height: 68px;
    background: $white;
    border: 1px solid $black-300;
    border-radius: 72px;
    transition: width 0.2s, height 0.2s;
    margin: 0 20px;

    &.scroll {
      width: 68px;
      height: 68px;
    }

    @media (min-width: 768px) {
      width: 100px;
      height: 100px;
      margin-top: 21px;
      margin-left: 61px;
    }
    @media (min-width: 991px) {
      width: 144px;
      height: 144px;
      margin-top: 21px;
      margin-left: 61px;
    }
  }

  .progress-reviews {
    + .divider {
      @media (max-width: 991.98px) {
        display: block !important;
        margin-bottom: 18px;
      }
    }

    span {
      font-size: 16px;
      color: $se-800;
      font-family: $primary-font;
      min-width: 42px;
    }

    .progress {
      width: calc(100% - 108px);
      border-radius: 10px;
      background: $black-50;
      margin-bottom: 18px;
      height: 22.5px;

      @media (min-width: 768px) {
        width: calc(100% - 120px);
      }

      @media (min-width: 1199.98px) {
        width: calc(100% - 135px);
      }
    }

    .progress-bar {
      background: $pr-500;
      border-radius: 10px;
    }
  }

  .filter-ratings-section {
    @media (min-width: 991.99px) {
      margin-top: -100px;
      &.mb-filter-section {
        margin-top: 0px;
      }
    }
    .filter-rating-chips {
      @media (max-width: 767px) {
        overflow: auto;
        display: flex;
        flex-wrap: nowrap;
        padding-bottom: 20px;
      }
    }

    .btn-review {
      font-size: 1rem;
      height: 48px;
      color: $se-800;
      font-family: $font-700;
      background-color: $white;
      border: 1px solid $black-500;
      padding: 12px 16px;
      margin-right: 1.375em;
      display: inline-block;
      margin-bottom: 8px;
      white-space: nowrap;
      cursor: pointer;

      &.selected {
        background-color: $secondary;
        border-color: $secondary;
        color: $white;
      }

      @media (max-width: 598px) {
        font-size: 0.75rem;
        height: 32px;
        display: inline-block;
        padding: 4px 8px;
        font-weight: 400;
        cursor: pointer;
      }

      @media (max-width: 1199.98px) {
        margin-right: 0.625em;
      }
    }
  }

  .filter-title {
    font-size: 20px;
    line-height: 28px;
    font-family: $font-800;
    color: $secondary;
    margin-bottom: 13px;
    @media (min-width: 597px) {
      margin-bottom: 18px;
    }
  }

  .divider {
    margin-bottom: 1.875rem;
    border: 1px solid $black-50;
  }

  // Reviews Section
  .reviews-section {
    margin-top: 22px;
    > div + div {
      margin-top: 15px;
    }
    .star-reviews {
      margin: 1.438rem 0px;
    }

    .tp-logo {
      border-radius: 50px;
      width: 80px;
      height: 80px;
      margin-right: 1.875rem;

      @media (max-width: 767px) {
        width: 50px;
        height: 50px;
        margin-right: 0.813rem;
      }
    }

    .tp-title {
      display: inline;
      font-size: 24px;
      font-family: $font-800;
      margin-right: 8px;
      color: $secondary;

      @media (max-width: 767px) {
        font-size: 16px;
      }
    }

    .tp-days {
      color: $secondary;
      font-size: 16px;
      display: inline-block;

      @media (max-width: 767px) {
        font-size: 14px;
        color: #655f5f;
        display: block;
      }
    }

    .review-title {
      font-size: 24px;
      font-family: $font-700;
      font-weight: 700;

      @media (max-width: 767px) {
        font-size: 18px;
      }
    }

    .review-para {
      color: $black-900;
      font-size: 18px;
      line-height: 25px;
      font-family: $primary-font;
      margin-bottom: 1.5rem;

      @media (max-width: 598px) {
        font-size: 16px;
      }
    }

    @media (min-width: 767px) {
      margin-top: 30px;
      .review-desc {
        padding-left: 110px;
        margin-top: -50px;
      }
      > div + div {
        margin-top: 65px;
      }
    }
  }
}