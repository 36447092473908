.listing-card {
  /* Sale */
  .card-sale {
    width: 15.625em;
    height: 20.9375em;
    .card-img-top-container {
      display: flex;
      align-items: center;
      height: 10.9375em;
      img {
        max-width: 100%;
        object-fit: contain;
        height: 100%;
      }
    }
    .card-body {
      padding-bottom: 0;
      padding: 0 0.625rem 0 !important;
      .store-info-container {
        height: 65px;
        padding-top: 10px;
        font-size: 14px;
        color: #001412;
        line-height: 16px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        .st-header {
          height: 16px;
          font-family: $font-600;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        .card-text {
          font-weight: 400;
          -webkit-line-clamp: 2;
          height: 32px;
        }
      }
      .price-info-container {
        height: 3.125em;
        .offer-text {
          display: block;
          height: 32px;
          line-height: 32px;
          font-family: $font-800;
          font-size: 24px;
          color: #00322E;
        }
        .end-date {
          line-height: 16px;
          font-size: 12px;
        }
      }
    }
    .card-bottom {
      display: grid;
      align-items: center;
      height: 2.8125em;
      background-color: #FEF4F4;
      border-radius: 0 0 1em 1em;
      &.card-bottom-align {
        justify-content: center;
      }
      .bottom-text {
        align-self: center;
        font-size: 12px;
        color: #001412;
      }
      .sponsored-badge {
        img {
          margin-right: 5px;
        }
        color: $secondary;
        background-color: $white;
        padding: 4px 10px;
        border-radius: 8px;
        font-size: 12px;
        line-height: 16px;
      }
    }

    @media (max-width: 991.98px) {
      h3 {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        min-height: inherit;
        white-space: inherit;
        min-height: 56px;
      }
      .duration {
        margin-top: 0.5em;
        span { display: block;}
        .dash {
          display: none;
        }
      }
    }

    .members-box {
      display: flex;
      padding: 1em;
      align-items: flex-start;
      justify-content: space-between;
      text-align: left;
      .h3 {
        -webkit-line-clamp: 2;
      }
      img {
        margin-left: 1em;
      }
      @media (min-width: 992px) {
        max-height: 118px;
        .desc {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
  }
  &.upcoming-sale .add-to-cal {
    display: block;
    width: 55%;
    height: auto;
    position: absolute;
    z-index: 10;
    right: 0;
    padding: 79.5478334% 0 0 0;
    background: url('../../../images/add-to-cal.png') no-repeat;
    background-size: contain;
  }
}


/* Ranking page */
.listing-grid {
  .card-sale {
    width: auto;
    height: inherit;
    .card-img-top-container {
      display: flex;
      align-items: center;
      height: 15.375em;
      img {
        max-width: 100%;
        object-fit: contain;
      }
    }
    .card-body {
      padding: 0 0.9375rem 0 !important;
      .store-info-container {
        height: 5rem;
        font-size: 18px;
        color: #001412;
        line-height: 20px;
        .st-header {
          height: 20px;
          display: block;
          font-weight: 700;
        }
        .card-text {
          height: 40px;
          font-size: 18px;
          line-height: 20px;
        }
      }
      .price-info-container {
        padding-top: 0.625rem;
        height: 4.625rem;
        .offer-text {
          display: block;
          height: 32px;
          line-height: 32px;
          font-family: $font-800;
          font-size: 32px;
          color: #00322E;
        }
        .end-date {
          line-height: 20px;
          font-size: 14px;
        }
        .view-count {
          padding: 0 !important;
          img {
            margin: -3px 3px 0 0;
            height: 13px;
            width: 18px;
          }
          small {
            line-height: 16px;
            font-weight: 500;
            font-size: 12px !important;
            color: #001412;
          }
        }
      }
    }
    .card-bottom {
      display: flex;
      flex-direction: row;
      justify-content: center;
      height: 3.375rem;
      background-color: #FEF4F4;
      border-radius: 0 0 1em 1em;
      .bottom-text {
        align-self: center;
        font-size: 14px;
        color: #001412;
      }
    }
  }
  .members-box {
    height: calc(168px - 2em);
    max-height: calc(100% - 1em) !important;
    margin-bottom: 1em;
    @media (min-width: 768px) {
      height: 136px;
    }
    @media (min-width: 992px) {
      height: calc(100% - 1em);
      max-height: calc(100% - 1em) !important;
    }
  }
}
