.department-header {
  margin-top: 1.5em !important;
  border-bottom: 1px solid $black-50;
  @media (min-width: 992px) {
    margin-top: 2.5em !important;
  }
}
.department-header .card {
  border-radius: 0px;
  border: 0;
  box-shadow: none;
  background: none;
  padding: 0.5em;
  padding-left: 0px;
  p {
    margin-bottom: 0;
  }
  h1 {
    color: $secondary;
    font-family: $font-800;
  }
}