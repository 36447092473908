/**** LISTING DETAIL *****/
.section-row {
  margin-bottom: 1.5em;
}
.mobile-report-section {
  margin-top: 5em;
  padding: 2em 1em;
  border-top: 1px solid #F0F3F0;
  border-bottom: 1px solid #F0F3F0;
}

.rate-this-deal {
  border-top: 1px solid $black-50;
  border-bottom: 1px solid $black-50;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  margin-bottom: 2em;
  display: none;
  .action-list {
    height: 48px;
    ul {
      margin-bottom: 0;
      li img {
        margin-right: 0.5em;
      }
    }
  }
  .btn {
    padding-left: 0;
    padding-right: 0;
  }
  .social-report-button-mobile {
    padding: 0 1.25em;
  }
  .h5, .list-inline-item:not(:last-child) {
    margin-right: 1em;
  }
  .h5 {
    margin-bottom: 0;
  }
  @media (min-width: 768px) {
    display: block;
  }
  .action-list {
    margin-right: 2em;
  }
  @media (min-width: 992px) and (max-width: 1439.98px) {
    .action-wrapper {
      flex-direction: column;
    }
  }
}


.listing-detail {
  .main-content {
    margin-bottom: 5em;
  }
  .breadcrumbs {
    margin-bottom: 1.5em;
    @media (min-width: 992px) {
      margin-bottom: 3.5em;
    }
  }
  .section-group .container {
    padding-left: 1em;
    padding-right: 1em;
  }
  .follow-brand-store {
    min-width: 126px;
  }
  .listing-group-header {
    padding-left: 0;
    padding-right: 0;
  }
  .image-container .price-drop-carousel {
    .carousel-item {
      overflow: hidden;
      height: 0;
      padding-top: 100%;
      position: relative;
      img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
      }
    }
    .carousel-control-prev-icon, .carousel-control-next-icon {
      width: 30px;
      height: 30px;
      opacity: 0;
    }
    &:hover {
      .carousel-control-prev-icon, .carousel-control-next-icon {
        opacity: 1;
      }
    }
    .carousel-control-prev-icon {
      background-image: url('../../../../assets/images/icons/chevron-circle-left.svg');
    }
    .carousel-control-next-icon {
      background-image: url('../../../../assets/images/icons/chevron-circle-right.svg');
    }
    .carousel-indicators {
      margin-bottom: -20px;
      li {
        background-color: $navy;
      }
    }
  }

  .deal-detail-actions {
    margin-top: 2em;
    margin-bottom: 2.5em;
    .btn-goto-store {
      padding: 0 4em;
    }
    .btn:not(:last-child) {
      margin-right: 1em;
      @media screen and (max-width: 375px) {
        margin-right: 0;
      }
    }
  }

  .detail-terms p {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 0;
  }

  .flex-direction-col {
    flex-direction: column;
  }

  .detail-terms .remove-clamp {
    -webkit-line-clamp: unset;
  }

  .tags-list {
    margin-bottom: 1.5em;
    .list-inline-item {
      padding: 0.25em 0.5em;
      border: 1px solid $se-800;
      border-radius: 0.5em;
    }
  }


  /* Coupon */
  .coupon-code {
    border-top: 1px solid $black-50;
    border-bottom: 1px solid $black-50;
    padding-top: 0.75em;
    padding-bottom: 0.75em;
    display: inline-block;
    margin-top: 1em;
    min-width: 348px;
  }
  .unique-code-checklist {
    .custom-control-label::before, .custom-control-label::after {
      // top: 0.25em;
    }
    .custom-checkbox .custom-control-input:checked~.custom-control-label::before {
      border-color: $main;
      background-color: $main;
    }
    .unique-coupon-item {
      .coupon-code {
        margin-top: 0;
      }
      &:not(:first-child) .coupon-code {
        border-top: 0;
      }
    }
  }

  /** Sale **/
  .sale-date {
    color: $se-400;
  }
  .sale-banner {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    img {
      width: 100%;
      max-height: 220px;
      object-fit: cover;
    }
  }


  .image-container {
    position: relative;
    img {
      object-fit: contain;
      &.coupon-detail-image {
        object-fit: cover;
      }
      &.sale-detail-image {
        object-fit: cover;
      }
    }
  }

}

.listing-brand-store {
  display: inline-block;
  text-align: center;
  width: 128px;
  margin-right: 1em;
  .profile-img {
    border: 1px solid $black-300;
  }
  .wrapper {
    position: relative;
    display: inline-flex;
    .btn-follow {
      position: absolute;
      right: -1em;
      bottom: 0;
    }
  }
  .brand-store-name {
    font-family: $font-600;
    font-size: 14px;
  }
}


.association {
  margin-bottom: 3em;
  .form-group {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }
}
