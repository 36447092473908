.bg-personalised-screen {
    background-color: $main;
    background-image: url('../../images/cloud2.png'), url('../../images/cloud2.png'), url('../../images/cloud2.png') , url('../../images/cloud2.png') , url('../../images/cloud2.png'), url('../../images//rays.svg');
    background-repeat: no-repeat;
    background-size: 60%, 28%, 15%, 30%, 80%, 150%;
    background-position-x: -50%, 115%, 70%, -10%, 160%, center ;
    background-position-y: 10%, 15%, 30%, 65%, 100%, center ;
    margin-top: -1rem;
    position: relative;
    min-height: 100vh;
    @media (min-width: 768px) {
        background-image: url('../../images/cloud2.png'), url('../../images/cloud2.png'), url('../../images/cloud2.png'), url('../../images/cloud2.png'), url('../../images/cloud2.png'), url('../../images/cloud2.png'), url('../../images//rays.svg');
        background-repeat: no-repeat;
        background-size: 24%, 28%, 20%, 30%, 25%, 20%, 100%;
        background-position-x: -60px, 90%, 15%, 34%, 115%, -8%, center ;
        background-position-y: 0, 0, 40%, 90%, 100%, 90%, center ;
    }

    .progress {
        height: 155px;
        width: 375px;
        background-color: transparent;
        background-image: url('./../../../assets/images/interactions/progressBird.gif');
        background-repeat: no-repeat;
        background-size: 90%, contain;
        background-position-x: center;
        background-position-y: bottom;
        @media (min-width: 768px) {
            width: 400px;
        }
    }

    .title {
        width: 323px;
        font-size: 2rem;
        line-height: 40px;
        font-weight: 900;
        @media (min-width: 768px) {
            font-size: 2.5rem;
            width: 450px;
        }
    }
    >div {
        position: absolute;
        top: 5rem;
        left: 50%;
        transform: translateX(-50%);
    }
}