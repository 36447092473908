.personalisation-prompt {
  background: $peach-50;
  border-radius: 2em;
  padding: 2rem 1.25em;
  background-image: url('../../images/cloud.png'), url('../../images/cloud.png');
  background-repeat: no-repeat;
  background-size: 10.375rem, 10.375rem;
  background-position: left -90px bottom -10px, right -80px bottom 20px;
  @media (min-width: 992px) {
    line-height: 58px;
    overflow: hidden;
    height: 16.75rem;
    align-items: center;
    border-radius: 1.5rem;
    background-size: 200px, 200px;
    background-position: bottom -35px center, right 150px top -60px;
  }
  .prompt-content {
    display: flex;
    position: relative;
    margin: 0;
    margin-left: -0.25rem;
    @media (min-width: 1200px) {
      padding: 0 2.5rem;
      margin: 0;
    }
    .close-icon {
      position: absolute;
      top: -10px;
      right: 10px;
      height: 17.5px;
      width: 17.5px;
      cursor: pointer;
      @media (min-width: 768px) {
        right: -10px;
      }
      @media (min-width: 1200px) {
        right: 50px;
      }
    }
  }
  .message-row {
    color: $secondary;
    font-family: $font-800;
    font-size: 24px;
    line-height: 32px;
    justify-content: flex-start;
    @media (max-width: 991px) {
      background-image: url('../../images/bird-flying-loop.gif');
      background-repeat: no-repeat;
      background-size: 320px, contain;
      background-position-x: 7rem, right;
      background-position-y: 0.2rem;
      margin-right: -19px;
    }
    @media (min-width: 992px) {
      font-size: 32px;
      line-height: 40px;
      background-image: url('../../images/bird-sitting-loop.gif');
      background-repeat: no-repeat;
      background-size: 300px;
      background-position: 4.9rem -7rem;
      &.logged-in {
        background-size: 300px;
        background-position: 4.9rem -4.4rem;
      }
    }
    @media (min-width: 1400px) {
      font-size: 36px;
      line-height: 42px;
      background-image: url('../../images/bird-sitting-loop.gif');
      background-repeat: no-repeat;
      background-size: 300px;
      background-position: 6.9rem -6.8rem;
      &.logged-in {
        background-size: 300px;
        background-position: 7rem -4.2rem;
      }
    }
    &.bg-img-none {
      background-image: none !important;
    }
    .bird-fly-off-animation {
      position: absolute;
      height: 100%;
      width: 100%;
      background-image: url('../../images/bird-flying-loop.gif');
      background-repeat: no-repeat;
      background-size: 320px, contain;
      background-position-x: 7rem, right;
      background-position-y: 0.5rem;
      margin-right: -45px;
      animation-name: fly-off-bird-bg;
      animation-duration: 1s;
      animation-timing-function: linear;
      animation-iteration-count: 1;
      @media (min-width: 768px) {
        background-position-x: 14rem, right;
      }
      @media (min-width: 1200px) {
        background-size: 450px, contain;
        background-position-x: 24rem, right;
      }
    }
    .break-text {
      @media (min-width: 992px) {
        word-spacing: 100vh; // added large word spacing so string will not fit on single line
      }
    }
  }
  .second-row {
    margin-top: 0.625rem;
    font-family: 'ABC Monument Grotesk';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    max-width: 20rem;
    color: $se-800;
    @media (min-width: 992px) {
      line-height: 32px;
      max-width: 100%;
    }
  }
  .p-green-text {
    color: $pr-500;
  }
  .image-animation {
    img.bird-flying-loop {
      height: 70px;
      margin-left: -9rem;
      margin-top: 0.75rem;
    }
    img.bird-sitting-loop {
      height: 300px;
      margin-left: -10rem;
      margin-bottom: -12.25rem;
    }
  }
  .flow-progress {
    .pill-content {
      width: 100%;
      @media (min-width: 992px){
        width: 85%;
      }
    }
    .badges-row {
      margin-top: 1.5rem;
      @media (min-width: 992px) {
        margin-top: 3rem;
      }
    }
    .tab-pill {
      display: inline-block;
      width: 66px;
      font-family: $font-700;
      font-size: 10px;
      line-height: 14px;
      text-align: center;
      color: $se-800;
      background: white;
      border: 1px solid $secondary;
      border-radius: 4px;
      @media (min-width: 992px) {
        width: 99px;
        font-size: 15px;
        line-height: 21px;
      }
    }
  }
  .stepper-list {
    display: flex;
    list-style-type: none;
    position: relative;
    z-index: 10;
    width: 100%;
    .step-item {
      width: 66px;
      display: flex;
      justify-content: center;
      &::after {
        content: '';
        width: 83%;
        position: absolute;
        border-top: 1px dashed $se-800;
        top: 50%;
        left: 45%;
        transform: translate(-45%);
        @media (min-width: 567px) {
            width: 88%;
        }
        @media (min-width: 768px) {
          width: 90%;
        }
        @media (min-width: 992px) {
          width: 80%;
        }
      }

      .current-progress-point {
        background: white;
        color: $se-800;
        border: 1px solid black;
        width: 22px;
        height: 22px;
        text-align: center;
        line-height: 22px;
        border-radius: 50%;
        z-index: 1;
        @media (min-width: 768px) {
          width: 33px;
          height: 33px;
        line-height: 33px;
        }
        &.active {
          color: $pr-500;
          border-color: $pr-500;
        }
        &.completed {
          background-color: $pr-500;
          border: none;
        }
        img {
          width: 15px;
          height: 15px;
          @media (min-width: 768px) {
            width: 22px;
            height: 22px;
          }
        }
      }
    }
  }
  .personalize-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 48px;
    width: 230px;
    margin-top: 2rem;
    background: white;
    border: 1px solid $black-500;
    border-radius: 24px;
    @media (min-width: 768px) {
      margin-top: 2rem;
    }
  }
}

@keyframes fly-off-bird-bg {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(187%);
  }
}
