.container-404-page {
  height: 70vh;
  .main {
    text-align: center;
    margin: auto;
  }
  .title-404 {
    font-size: 56px;
    font-family: $font-900;
    color: $secondary;
    line-height: 53px;

    background-image:  url('../../images/bird-404.svg');
    background-repeat: no-repeat;
    background-size: 7rem;
    background-position: 25.25rem 0.75rem;
    padding-top: 70px;

    @media (max-width: 598px)  {
      font-size: 32px;
      line-height: 38px;
      background-size: 4rem;
      background-position: 14.25rem 2.7rem;
      padding-right: 1.25rem;
    }
  }
  .para-404 {
    font-size: 32px;
    line-height: 40px;
    color: #00322E;
    font-family: $font-700;
    @media (max-width: 598px)  {
      font-size: 18px;
      line-height: 21px;
      span {
        display: block;
      }
    }
  }
  .btn-homepage {
    background-color: $pr-200;
    border: 2px solid $pr-200;
    border-radius: 27.5px;
    color: #000000;
    width: 217px;
    font-size: 18px;
    line-height: 21px;
    margin-top: 2.8125rem;
    &:hover {
      background-color: $main;
      border: 2px solid $main;
    }
  }

}
