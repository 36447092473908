h1, .h1 , h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, h7, .h7 {
  color: $black-900;
}

h1, .h1 , h2, .h2, .display-1 {
  font-family: $font-900;
}
h3, .h3, h4, .h4, h5, .h5 {
  font-family: $font-800;
}
h6, .h6, h7, .h7 {
  font-family: $font-700;
}

strong, .strong {
  font-family: $font-600;
}

.label-tag {
  margin-right: 2px;
}
.text-muted { color: $black-600;}

h2, .h2 {
  font-size: 2em;
  line-height: 2.5rem;
}
h3, .h3 {
  font-size: 1.5em;
  line-height: 2rem;
}
h4, .h4 {
  font-size: 1.25em;
  line-height: 1.75rem;
}
h5, .h5 {
  font-size: 1em;
}
.display-1 {
  font-size: 4em;
}
.body-0 {
  font-size: 1.5em;
}
.body-0-strong {
  font-size: 1.5em;
  font-family: $font-600;
}

.text-color-primary {
  color: $pr-500;
}

//Mobile Header sizes
@media (max-width: 991.98px) {
  h1, .h1 {
    font-size: 2em;
  }
  h2, .h2 {
    font-size: 1.5em;
    line-height: 2rem;
  }
  h3, .h3 {
    font-size: 1.25em;
    line-height: 1.75rem;
  }
  h4, .h4 {
    font-size: 1em;
    line-height: 1.5rem;
  }
  h5, .h5 {
    font-size: 0.875em;
  }
  h6, .h6 {
    font-size: 0.75em;
  }
  h7, .h7 {
    font-size: 0.625em;
  }
  .display-1 {
    font-size: 2.5em;
  }
}