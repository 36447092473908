.form-group, .form-control {
  label:not(.custom-control-label) {
    margin-bottom: 0.5rem;
    font-size: 14px;
    font-family: $font-600;
  }
  .file-upload {
    display: none;
  }
}
.form-control {
  height: 48px;
  border-color: $black-500;
  border-radius: 0.5em;
  &.error {
    border-color: #E61A1A;
    border-width: 2px;
  }
}
textarea.form-control {
  min-height: 48px;
}

.input-group {
  .input-group-text {
    padding: 1.45rem 0.75rem;
    background: none;
    border-color: $black-500;
  }
  .form-control {
    border-left: 0;
  }
}

/* Placeholder */
input::placeholder, textarea::placeholder {
  color: $se-400 !important;
}
/*  ---------------------- */

.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.bs-tooltip-right .arrow::before {
  border-right-color: #f1f1f1;
}

.tooltip-inner {
  color: #01213f;
  background: #f1f1f1;
  z-index: -1;
  box-shadow: 2px 2px 4px 0px rgba(161, 161, 161, 1);
}

.smaller-checkbox {
  width: 9px;
  height: 9px;
  margin: 11px 3px 7px 0;
  border: solid 1px $black-500;
  background-color: #ffffff;
}

.custom-control-label {
  font-size: 11px;
  @media (min-width: 375px) {
    font-size: 14px;
  }
}
.custom-checkbox, .custom-radio {
  padding-left: 2.25em;
  .custom-control-label::before {
    left: -2.25em;
    top: 0;
  }
}

/* Checkbox */
.custom-checkbox {
  .custom-control-label::before {
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 0.4rem;
    border: 1px solid $black-500;
    box-shadow: none;
    @media (min-width: 375px) {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 0.5rem;
    }
  }
  .custom-control-input:focus ~ .custom-control-label::before {
    border-color: $black-500;
    box-shadow: none;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: $main-100 !important;
    background-color: $main-100;
    box-shadow: none;
  }
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background: no-repeat 50% 50%;
  width: 24px;
  height: 24px;
  top: 0;
  left: -2.25em;
  background-image: url('../../../assets/images/icons/Tick.svg');
}

/* Radio */
.custom-radio {
  margin-bottom: 1em;
  .custom-control-label::before {
    width: 24px;
    height: 24px;
    top: -0.125em;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: $main;
    background-color: $main;
  }
  .custom-control-input:checked ~ .custom-control-label::after {
    width: 12px;
    height: 12px;
    background: $secondary;
    border-radius: 1em;
    left: -26px;
  }
  .custom-control-label, label {
    font-family: $primary-font;
    margin-bottom: 0 !important;
  }
}

/*  form-control-select  */
.form-control-select {
  background: url('../../../assets/images/icons/chevron-down.svg') no-repeat calc(100% - 0.75em);
  padding-right: 2em;
  div[class*="control"] {
    border: 0;
    min-height: inherit;
    box-shadow: none;
  }
  div[class*="ValueContainer"] {
    padding: inherit;
  }
  div[class*="placeholder"] {
    color: $se-400;
  }
  div[class*="IndicatorsContainer"] {
    display: none;
  }
}

/* Select */
select.form-control {
 -webkit-appearance: none;
 -moz-appearance: none;
 appearance: none;       /* Remove default arrow */
 background: url('../../../assets/images/icons/chevron-down.svg') no-repeat calc(100% - 0.75em);   /* Add custom arrow */
}

.form-field-password {
  position: relative;
  input {
    padding-right: 42px;
  }
  img {
    position: absolute;
    width: 24px;
    cursor: pointer;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}

