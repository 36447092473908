.swal-modal {
  .swal-title {
    font-size: 16px;
    font-weight: 400;
    color: $navy;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
  .swal-button {
    background-color: $pr-200;
    color: $se-800;
    border-radius: 5em;
    &:hover {
      background-color: $main;
    }
  }
  .swal-footer {
    text-align: center;
    margin-top: 0;
    padding-top: 0;
  }
}

@media (max-width: 576px) {
  .modal {
    background-color: $main;
  }
}
