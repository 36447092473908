.ranking-page-header {
  margin-bottom: 2em;
  margin-top: 2.5em;
  @media (max-width: 575px) {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }
}
.results-title {
  @media (max-width: 575px) {
    font-size: 24px;
  }
}
.mb-column-icon {
  margin-right: 12px;
}

.search-page {
  .google-search-banner {
    background: $black-100;
    padding: 0.75rem 1.25rem;
    font-size: 16px;
    margin: -1rem -1rem 1.5rem -1rem;
    h1 {
      font-size: 16px;
      font-family: $primary-font;
    }
    img.google {
      width: 79px;
      height: 30px;
    }
  }
}
