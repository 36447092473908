table.price-history-links {
  @media screen and (max-width: 424px) {
      font-size: 12px;
  }
  @media (min-width: 425px) and (max-width: 768px) {
      font-size: 14px;
  }
  .col-store {
    width: 75%;
    @media (max-width: 768px) {
      width: 75%;
      min-width: 80px;
    }
  }
  .col-price {
    width: 25%;
    text-align: right;
  }
  .colorbadge {
    width: 15px;
    height: 15px;
    float: left;
    border-radius: 15px;
    top: 2px;
    position: relative;
    margin-right: 5px;
  }
  td {
    padding: 0.25rem;
    border: 0px;
    vertical-align: middle;
    @media screen and (max-width: 425px) {
      padding: 0;
    }
  }
}