.carousel {
  &#adBannerCarousel {
    .carousel-indicators {
      bottom: -35px;
      li {
        background-color: grey;
      }
    }
  }
}

.full-width-container {
  max-width: 1600px;
}

.top-list-banner {
  .carousel-control-prev,
  .carousel-control-next {
    display: none;
  }
  ol {
    bottom: -35px;
    li {
      background-color: grey;
    }
  }
}

.section-group {
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
  .section-bg {
    border-radius: 2em;
    padding: 2.5em;
    height: 965px;
    @media (min-width: 992px) {
      height: 975px;
    }
  }
  .section-group-body {
    position: relative;
    left: 0;
    margin-top: calc(-965px + 2em);
    @media (min-width: 992px) {
      margin-top: calc(-975px + 2.5em);
      height: calc(975px - 2.5em);
    }
  }
}

.listing-group-header {
  margin-bottom: 1em;
  h2 {
    margin-bottom: 0;
    color: $secondary;
    font-family: $font-800;
  }
  .subtitle {
    margin-bottom: 0;
    font-size: 14px;
    margin-top: 0.6250em;
  }
}
.listing-carousel {
  width: 100%;
  overflow: hidden;
  position: relative;
  max-width: 1536px;
  margin: auto;
  padding: 0px 1.4rem;
  @media (min-width: 768px) {
    padding: 0px 2.688rem;
  }
}
.listing-group-carousel::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0px;
  height: 8px;
  display: none;
}
.listing-group-carousel::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.listing-group-carousel {
  width: 100%;
  overflow: scroll;
  scroll-behavior: smooth;
  padding: 0.2em 1em 1em 0.2em;
  max-width: 1440px;
  margin: auto;
  .listing-card {
    margin-right: 1em;
  }
}
.search-page .listing-group-carousel {
  padding-top: 0.5em;
  width: 100%;
  overflow: scroll;
  padding-bottom: 1em;
  padding-right: 1em;
  .listing-card {
    margin-right: 0em;
    padding-left: 1em;
    transform: none;
  }
}
.carousel-arrow {
  width: 56px;
  height: 56px;
  display: flex;
  position: absolute;
  right: -4px;
  top: calc(50% - 110px);
  justify-content: center;
  z-index: 100;
  .carousel-arrow-img {
    width: 29px;
    &:hover {
      cursor: pointer;
    }
  }
  &.carousel-arrow-left {
    left: -8px;
  }
  &.trendlist-chevron {
    top: -3%;
    background: #fafafa;
    right: 24px;
  }
}

/** Find More **/
.listing-find-more {
  border: 1px dashed $black-500;
  border-radius: 1em;
  &:hover {
    text-decoration: none;
  }
}
.carousel-indicators {
  .active {
    background-color: $pr-500;
  }
  li {
    background-color: #b3b3b3;
  }
  .indicators {
    border-top: none;
    border-bottom: none;
    border-radius: 8px;
    width: 10%;
    height: 4px;
    bottom: 4px;
  }

  @media (max-width: 767.98px) {
    visibility: visible;
    justify-content: left;
    margin: 0px;
    margin-left: 10%;
    bottom: 8px;
  }
}

.carousel-arrow {
  @media (max-width: 767.98px) {
    visibility: hidden;
  }
}

.carousel.slide .carousel-arrow {
  top: calc(50% - 28px);
}

.carouselContainer {
  max-height: 244px;
  overflow: hidden;
  border-radius: 2em;
  background-color: #e5ebea;
}

.carousel-arrow.left {
  left: 2em;
  top: 50%;
  transform: translateY(-50%);
}



.carousel-arrow.right {
  top: 50%;
  transform: translateY(-50%);
}
