.modal-favourites {
  .modal-dialog-centered {
    align-items: flex-start;
    @media (min-width: 768px) {
      align-items: center;
    }
  }
  .subtitle {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 1.2rem;
    @media (min-width: 768px) {
      margin-bottom: 1.5rem;
    }
  }
  .modal-dialog {
    padding: 1rem 1rem 0;
    margin: auto;
    @media (min-width: 768px) {
      max-width: 600px;
    }
    @media (min-width: 992px) {
      max-width: 800px;
    }
  }
  .modal-header {
    padding: 1rem;
    color: $secondary;
    .header {
      margin-top: 16px;
      &.m-top{
        margin-bottom: 3.75rem;
        margin-top: 1rem;
        @media (min-width: 768px) {
          margin-top: 20px;
        }
      }

    }
    @media (min-width: 768px) {
      padding: 40px;
    }
    h3 {
      color: $secondary;
      font-size: 1.25rem;
      line-height: 28px;
      font-weight: 800;
      @media (min-width: 768px) {
        font-size: 2rem;
        margin-bottom: 1.563rem;
      }
    }
    .close {
      padding: 0px;
      display: none;
      width: 17.5px;
      height: 17.5px;
      opacity: 1;
      img {
        width: 100%;
      }
      @media (min-width: 768px) {
        display: inline-block;
        margin-top: -1.5rem;
        margin-right: -1.5rem;
      }
    }

    .label-skip {
      font-size: 14px;
      color: $pr-500;
      text-decoration: underline;
      font-weight: 700;
      span {
        cursor: pointer;
      }
    }
  }
  .modal-body {
    margin-top: 32px;
    padding: 4px 14px 14px;
    height: calc(100vh - 380px);
    overflow-y: auto;
    max-height: 508px;
    @media (min-width: 768px) {
      padding: 4px 40px 40px;
      height: calc(100vh - 320px);
    }
  }
  .modal-footer {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    .btn {
      width: 110px;
      padding: 0px;
      @media (min-width: 375px) {
        width: 132px;
      }

    }
  }
  .modal-content {
    border-radius: 1em;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08), 0px 10px 16px -4px rgba(0, 0, 0, 0.1);
    border: 0px;
    @media (min-width: 768px) {
      max-height: 600px;
    }
    .progress-round {
      width: 105px;
      height: 81px;
      display: inline-table;
      h3, p {
        margin-bottom: 0;
      }
    }
    .sidebar {
      width: 260px;
      .logo {
        border: solid 0.3px rgba(151,151,151,0.3);
        width: 50px;
        height: 50px;
      }
    }
    .grid-item-label {
      font-size: 12px;
      line-height: 1rem;
      font-family: $font-600;
      margin-top: 1rem;
      text-align: center;
    }
    .favourites-category {
      .grid-item-label {
        font-size: 10px;
        line-height: 20px;
        @media (min-width: 360px) {
          font-size: 14px;
        }
      }
      .favourites-category-item {
        width: 76px;
        height: 76px;
        border-color: $black-300 !important;
        position: relative;
        @media (min-width: 768px) {
          width: 96px;
          height: 96px;
        }
        .btn-follow{
          width: 36px;
          height: 36px;
          line-height: 36px;
          position: absolute;
          bottom: -10%;
          right: -10%;
          border: 1px solid #C5C5C5;
          @media (min-width: 768px) {
            width: 48px;
            height: 48px;
            line-height: 48px;
          }
          svg {
            fill: $black-300;
          }
        }
        img {
          max-height: 50px;
          max-width: 54px;
          width: auto !important;
        }
        &.active {
          border: 1px solid $peach !important;
        }
      }
    }
    .favourites-stores {
      .store-item {
        margin-bottom: 2em;
        width: 33%;
        @media (min-width: 375px) {
          width: 24%;
        }
        :hover {
          cursor: pointer;
        }

        @media (min-width: 992px) {
          flex: 0 0 16.2857143%;
          max-width: 16.2857143%;
        }
        .btn-follow {
          margin-left: 50px;
          position: absolute;
          width: 32px;
          height: 32px;
          line-height: 32px;
          bottom: -10%;
          right: -10%;
          border: 1px solid #C5C5C5;
          @media (min-width: 768px) {
            width: 48px;
            height: 48px;
            line-height: 48px;
          }
          .btn-content {
            width: 29px;
            height: 29px;
          }
        }
        .store-item-logo {
          position: relative;
          .store-item-logo-inside {
            width: 64px;
            height: 64px;
            overflow: hidden;
            border-radius: 50rem;
            border: 1px solid $black-300;
            @media (min-width: 768px) {
              width: 94px;
              height: 94px;
            }

            img {
              object-fit: cover;
              height: 100%;
            }
            &.active {
              border: 1px solid $peach !important;
            }
          }
        }
        .small {
          line-height: 14px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
  }
  &#favouritesFollowModal {
    .close {
      position: absolute;
      right: 1em;
    }
  }
}

.modal-full {
  margin: 0;
  &.modal-dialog {
    max-width: inherit;
  }
  .modal-content {
    max-height: 100vh;
    position: relative;
    border-bottom: 0;
    padding: .5rem;
    @media (min-width: 768px) {
      max-height: 600px;
    }
    .modal-footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      z-index: 2;
      background: white;
    }
    .modal-body {
      z-index: 1;
    }
    .bottom-gradient {
      z-index: 0;
    }
  }
}
.isMobile .modal-full .modal-body {
  max-height: calc(100vh - 195px);
}

@media (max-width: 576px) {
  .modal-favourites {
    .modal-header {
      .label-skip {
        margin-top: -1rem;
      }
      .close {
        display: none;
      }
    }
  }
}



