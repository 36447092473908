.store-brand-summary {
  border: 1px solid $black-500 !important;
  padding-top: 2em;
  padding-bottom: 2em;
  border-radius: 2em;
  margin-left: -1rem;
  margin-right: -1rem;
  @media (min-width: 768px) {
    margin-left: 0;
    margin-right: 0;
  }
  @media (min-width: 992px) {
    align-items: center;
  }
  .listing-brand-store {
    width: 144px;
    margin-right: 1.5em;
    @media (min-width: 580px) {
      margin-left: 1.5em;
      margin-right: 2em;
    }
    @media (min-width: 768px) {
      margin-left: 4em;
      margin-right: 5em;
    }
  }
  h5 {
    margin-bottom: 0;
    line-height: 1.5em;
  }
  .store-stats {
    margin-top: 1em;
    margin-bottom: 1.5em;
    flex-direction: column;
    .dash {
      display: none;
    }
    @media (min-width: 992px) {
      flex-direction: row;
      .dash {
        display: inline;
      }
      .dash:last-child {
        display: none;
      }
    }
  }
  @media (min-width: 992px){ 
    .store-social-payment {
      .store-ql:nth-child(2) {
        margin-left: 6em;
      }
    }
  }
  .social-icons .list-inline-item {
    margin-right: 1rem;
  }
  .payment-icons .list-inline-item {
    margin-right: 0.5em;
    @media (min-width: 992px) {
      margin-right: 1em;
    }
  }
  .store-custom-description {
    font-size: 14px;
  }
}
.store-review {
  margin-top: 18px;
  display: flex;
  margin-left: -144px;
  flex-direction: row;
  flex-wrap: wrap;

  .widget-svg {
    width: 22px !important;
    height: 22px !important;
  }

  .store-review-view {
    flex-basis: 45%;
  }

  .tustpilot-icon {
    flex-basis: 50%;
    margin-top: -20px;
  }

  .star-ratings {
    flex-basis: 55%;
  }

  .star-ratings,
  .tustpilot-icon {
    text-align: center;
  }

  &-view {
    padding-left: 15px;
    font-size: 16px;
    line-height: 1.3;

    &-btn {
      color: $pr-700 !important;
      text-decoration: underline !important;
      font-style: italic;
      display: block;
      cursor: pointer;
    }

    span {
      display: block;
    }
  }

  .tustpilot-icon img {
    max-width: 100px;
  }

  @media (min-width: 375px) {
    .widget-svg {
      width: 37px !important;
      height: 37px !important;
    }

    .tustpilot-icon {
      margin-top: 0px;
    }

    &-view {
      span {
        display: inline-block;
      }
    }
  }

  @media (min-width: 768px) {
    flex-direction: column;
    margin-left: 0;

    &-view {
      padding-left: 0px;
      margin: 10px 0;

      &-btn {
        display: inline-block;
      }

      span {
        display: inline-block;
      }
    }

    .star-ratings,
    .store-review-view,
    .tustpilot-icon {
      flex-basis: auto;
      text-align: left;
    }
  }

  @media (min-width: 992px) {
    flex-direction: row;
    align-items: center;

    &-view {
      margin: 0 15px;
    }
  }
}
.store-summary {
  flex:1;
  .store-description {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    position: relative;
    .more {
      position: absolute;
      bottom: 0;
      background: #f7f7f7;
      padding-left: 5px;
    }
  }
}

.isMobile {
  .store-summary {
    .social-icons {
      img {
        width: 32px;
      }
    }
    .payment-icons {
      .list-inline-item {
        margin-right: 0.25rem;
      }
    }
  }
}

.reviews-on-storepage {
  .store-reviews {
    height: auto;
    overflow: hidden;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08), 0px 10px 16px -4px rgba(0, 0, 0, 0.1);
    border-radius: 2rem;
    .reviews-section {
      @media (min-width: 767px){
        max-height: 1000px;
        overflow: hidden;
        overflow-y: auto;
      }
    }
  }
}