.clear, .btn-clear {
  color: #001412;
  font-size: 12px;
  padding: 0 0.625em 0.5em;
  text-decoration: underline;
  height: 16px;
  line-height: 16px;
  &.large {
    font-size: 16px;
    line-height: 24px;
    height: 24px;
    align-self: center;
    margin-top: -8px;
    &.mobile-clear {
      position: absolute;
      right: 20px;
    }
  }
}
.btn-clear-all {
  font-size: 16px;
  padding: 0 2em 0 0.5em;
  border-radius: 5em;
  background: $black-50 url('../../../assets/images/icons/icn_clear.svg') no-repeat 90%;
  background-color: $pr-200;
  color: $se-800;

  &:hover {
    background-color: $main;
  }
  &.active {
    background-color: $main;
  }
  &.inactive {
    background: $main-inactive
  }
}
.filter-group {
  margin-top: 2em;
  .btn-clear, .clear {
    display: none;

  }
  .list-group-item {
    border: 0;
    margin-bottom: 0;
    border-radius: 0;
    .header-label-wrapper:after {
      position: absolute;
      content: "";
      background: url('../../../assets/images/icons/icn_filter_plus.svg') no-repeat;
      width: 24px;
      height: 24px;
      float: right;
      right: 5px;
    }
    .header {
      border-bottom: 1px solid $black-50;
    }
    .selected-filter-preview {
      float: left;
      font-size: 12px;
      font-weight: 400;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 20px;
      color : #808080;
      @media (max-width: 1200px) {
        padding: 0 50px 0 0;
      }
    }
    .header[aria-expanded="true"] {
      .clear, .btn-clear {
        display: inline-block;
      }
      .header-label-wrapper:after {
        content: "";
        background: url('../../../assets/images/icons/icn_filter_minus.svg') no-repeat;
        width: 24px;
        height: 24px;
        filter: invert(60%) sepia(35%) saturate(846%) hue-rotate(91deg) brightness(92%) contrast(98%);
      }
      .selected-filter-preview {
        display: none;
      }
    }
  }
  .search-filter {
    margin: -15px;
    margin-bottom: 1.5rem;
    .form-control {
      background: #fff url('../../../assets/images/icons/icn_search.svg') no-repeat 15px;
      padding-left: 48px;
    }
    @media (max-width: 1199px) {
      margin: auto 0 1.5rem;
    }
  }
  .custom-checkbox {
    margin-bottom: 1em;
  }
  .checklist-group {
    max-height: 240px;
    overflow-y: scroll;
  }
  .price-range {
    font-size: 13px;
    input {
      color: #335B58;
    }
    .btn-link {
      color: $navy;
      font-size: 11px;
      font-weight: normal;
      border: 1px solid #808080;
      border-radius: 24px;
    }
  }
  .filter-pills-container {
    margin: 1em 0.125em 0.375em;
    padding: 0 0.375em;
    .filter-pill {
      display: inline-block;
      position: relative;
      font-family: "ABC Monument Grotesk", sans-serif;
      font-size: 12px;
      font-weight: 400;
      padding: 0 0.6875em 0 0.5em;
      border-radius: 5em;
      text-decoration: none;
      background: #DCFAD2;
      height: 24px;
      line-height: 24px;
      margin : 0 0 0.625em 0;
      &::after {
        content: url('../../../assets/images/icons/icn_clear.svg');
        position: inherit;
        top: 4px;
        margin-left: 0.375em;
        cursor: pointer;
      }
    }
  }
  .see-all-list {
    cursor: pointer;
    margin-left: 2rem;
    font-size: 14px;
    text-decoration: underline;
    font-style: italic;
  }
}


