.pagination {
  font-size: 11px;
  margin-top: 0;
  .page a, .next a, .last a, .first a, .prev a {
    position: relative;
    float: left;
    padding: 6px 12px;
    margin-left: -1px;
    line-height: 1.42857143;
    color: #337ab7;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #ddd;
    &:hover {
      background-color: #f7f9fa;
    }
    a {
      text-decoration: none;
    }
  }
  .current, .gap {
    position: relative;
    float: left;
    padding: 6px 12px;
    margin-left: -1px;
    line-height: 1.42857143;
    color: #337ab7;
    text-decoration: none;
    background-color: #fff;
  }
  .gap {
    border: none;
    margin-left: 0;
  }
  .current, .current:hover {
    border: 1px solid #007bff;
    background-color: #007bff;
    color: white;
  }
}


/***** PAGINATION *****/
.pagination {
  .page-link {
    background: none;
    border: none;
    font-size: 1.1428571428571428rem;
    color: $black;
    padding: 0.1rem 0.25rem;
    margin-left: 0.25em;
    margin-right: 0.25em;
  }
  .page-item.active .page-link {
    background: #7d8597;
  }
  .page-item:first-child, .page-item:last-child {
    margin-right: 2em;
    margin-left: 2em;
  }
}