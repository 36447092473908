
/***** POP UP NOTIFICATIONS ******/
.card-notification {
  position:fixed;
  display: inline-block;
  left: 10px;
  bottom: 10px;
  font-size: 13px;
  z-index: 5;
  border-radius: 0.5em;
  color: black;
  max-width: 387px;
  width: 387px;
  height: 151px;
  .headline {
    line-height: 1.2;
    height: 30px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-weight: 100;
  }
  .lead {
    font-size: 13px;
    font-weight: 100;
    &.notification-coupon {
      border: 1px dashed;
      display: inline-block;
      padding: 3px 5px;
      margin-top: 7px;
    }
  }
  h5 {
    font-weight: 400;
    font-size: 16px;
    margin-top: 3px;
  }
  .store-name {
    font-size: 10px;
    color: #858586;
  }
  .notification-image {
    width: 150px;
    height: 150px;
    img {
      width: 135px;
      min-width: 135px;
      height: 100%;
      object-fit: cover;
      border-radius: 0.5em 0 0 0.5em;
      &.event-image {
        height: 74px;
        border-radius: 0.5em 0 0 0;
      }
      &.logo-image {
        height: 74px;
        border-radius: 0 0 0 0.5em;
        object-fit: contain;
      }
    }
  }
  &#deal-notification {
    height: 169px;
    .notification-image {
      height: 168px;
    }
    .deal-notification-bottom {
      position: absolute;
      bottom: 0;
      width: calc(100% - 0.5em);
    }
  }
  .notification-content {
    position: relative;
    border-left: 1px solid rgba(200, 200, 200, 0.2);
    .icon-close {
      position: absolute;
      right: 5px;
      top: 5px;
      width: 20px;
      height: 20px;
      background: url('../../../assets/images/icons/icn-calendar.svg') no-repeat;
      background-size: cover;
    }
    .little-birdie-tag {
      margin-right: 2px;
    }
    .brand-image {
      padding-right: -149px;
      width: 50px;
      height: 50px;
      object-fit: cover;
    }
  }
  .close-img {
    padding-top: 5px;
    padding-left: 20px;
  }
  &#coupon-notification {
    .notification-image img {
      object-fit: contain;
    }
  }
}