.tile-transition-enter, .tile-transition-appear {
  -webkit-transition: opacity 0.4s ease-in;
  -moz-transition: opacity 0.4s ease-in;
  -o-transition: opacity 0.4s ease-in;
  opacity: 0;
}

.tile-transition-enter-active, .tile-transition-appear-active {
  -webkit-transition: opacity 0.4s ease-in;
  -moz-transition: opacity 0.4s ease-in;
  -o-transition: opacity 0.4s ease-in;
  opacity: 1;
}

.tile-transition-exit {
  opacity: 1;
}
  
.tile-transition-exit.tile-transition-exit-active {
  opacity: 0;
  -webkit-transition: opacity 400ms ease-out;
  -moz-transition: opacity 400ms ease-out;
  -o-transition: opacity 400ms ease-out;
}

.tile-transition-exit-done {
  opacity: 0;
  }