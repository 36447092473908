.bm-overlay {
  z-index: 1040 !important;
  background: rgba(0,0,0,0.7) !important;
  height: 100vh !important;
}
.bm-overlay, .user-slider {
  top: 0;
  width: 100% !important;
}
.user-slider {
  max-width: 450px !important;
}
.bm-menu {
  background: white;
}

.bm-cross-button {
  z-index: 100;
  right: 1em !important;
  top: 1em !important;
}

.dep-icon-mobile {
  text-align: center;
  display: inline-block;
  margin-right: 10px;
}

.truncate{
  width: 95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $secondary;
}

.cat-slider {
  .bm-cross-button {
    left: 15px !important;
  }
}

.mobile-body-overflow {
  height: auto;
  position: relative;
  overflow: hidden;
}


.mobile-search {
  width: 100%!important;
  .btn-slider-search {
    border-radius: 0 13px 13px 0;
  }
  .input-group {
    z-index: 1;
  }
  .search-close {
    z-index: 1;
    height: 44px;
    padding-left: 4px;
    margin-left: -0.5rem;
    color: $secondary;
  }
  .search-suggestions {
    position: fixed;
    left: 0;
    width: 100%;
    top: 0;
    height: 100%;
    max-height: 100vh;
    padding-top: 3rem;
    border-radius: 0;
    overflow: hidden;
    overflow-y: auto;
    background: white;
    .trendingHeading {
      padding: 1.75rem 1rem 0.5rem;
      font-family: 'ABC Monument Grotesk Black';
      font-size: 18px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: $secondary;
    }
    .list-group-item {
      border: none;
      border-radius: 0px;
      margin-bottom: 0px;
      border-bottom: 1px solid $black-50;
      &:first-child {
        margin-top: 10px;
      }
      &.google-search-link {
        background: $black-50;
        img.google {
          width: 79px;
          height: 30px;
        }
      }
    }
    .search-empty-text {
        height: 100%; 
        width: 16rem;
        margin: auto; 
        align-items: center; 
        justify-content: center;
        white-space: initial;
        text-align: center;
        font-size: 16px;
        line-height: 24px;
        color: $black-500;
        img {
          padding: 0.657rem;
          background-color: $main;
          border-radius: 50%;
        }
    }
  }
}


.side-block{
  .side-block-header {
    padding: 0.75em 3.5em 0.75em 1em;
  }
  .nav-side-bar {
    background-color: $main-100;
    border-radius: 1em;
    margin: 0.5em;
    .nav-tab-options {
      padding-top: 0.5em;
      padding-bottom: 0.5em;
    }
    .nav-sidebar-item {
      &:hover {
        cursor: pointer;
      }
      .nav-sidebar-item-wrapper {
        display: flex;
        align-items: center;
        padding: 0.75em 1em;
      }
      .nav-item-image {
        height: 24px;
        width: 24px;
        margin-right: 0.5em;
        img {
          max-width: 100%;
          height: 100%;
          width: 100%;
        }
      }
      .new-pill {
        display: inline-flex;
        background-color: $pr-200;
        padding: 4px 8px;
        border-radius: 16px;
        margin-left: 3px;
      }
      .new-pill-text {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
      }
    }
  }
  .mobile-nav-back {
    margin: 1rem 0;
    width: 100%;
    align-items: center;
    .mobile-nav-heading {
      font-family: 'ABC Monument Grotesk Heavy';
      font-size: 20px;
      line-height: 28px;
      align-items: center;
      text-align: center;
      color: $secondary;
      margin-left: 4rem;
    }
  }
  .side-block-field{
    height: calc(100vh - 139px);
    overflow: scroll;
    .list-group-item {
      border: 0;
      a {
        text-decoration: none;
        display: flex;
        align-items: center;
      }
    }
    &.sidebar-profile {
      img {
        margin-right: 0.5em;
      }
    }
  .fontFamily {
    font-family: 'ABC Monument Grotesk Medium';
  }

  }
  .department-list {
    .list-group-item {
      position: relative;
      &:after {
        top: 0;
        content: "";
        width: 12px;
        height: 100%;
        background: url('../../../assets/images/icons/chevron-right.svg') no-repeat 50%;
        position: absolute;
        right: 1em;
      }
    }
  }
}



/* BOTTOM ACTION BAR */
.detail-action-bar {
  background: white;
  .nav {
    padding: 5px 0 !important;
    a {
      .small {
        font-size: 12px;
      }
      &:hover {
        text-decoration: none;
      }
    }
    .nav-item {
      .btn, .btn-content {
        padding: 0 !important;
        border: 0 !important;
        height: auto;
        line-height: inherit;
        img {
          width: 24px;
        }
      }
    }
  }
  &.mobile-action-bar {
    .nav-item a {
      display: block;
      width: 60px;
      margin: auto;
      border-radius: 5px;
      padding: 4px 0;
      &.active {
        background: #f1eded;
      }
    }
  }
}
