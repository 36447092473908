.item-detail-quick-popup {
  background-color: transparent;

  .modal-dialog {
    max-width: 300px;
    width: 100%;
    margin: auto;

    @media (max-width: 767px) {
      max-width: 90%;
      margin: 10px auto;
    }
    
    .modal-content {
      border-radius: 18px;
      box-shadow: 0px 0px 6px 0px #00000040;
      overflow: hidden;
    }

    .modal-body {
      // max-width: 300px;
      padding: 0;

      hr {
        border-top: 1px solid #F0F3F0
      }

      .store-img {
        position: absolute;
        height: 54px;
        width: 54px;
        background-color: white;
        border: 1px solid rgba(0, 0, 0, 0.35);
        border-radius: 100%;
        bottom: 10px;
        left: 10px;
        box-sizing: border-box;
        object-fit: contain;

        &.cashrewards {
          border: 0;
        }
      }

      .close {
        padding: 2px;
        position: absolute;
        right: 12px;
        top: 12px;
        opacity: 1;
        line-height: 1;
        font-size: 16px;
        z-index: 1;

        img {
          height: 20px;
          width: 20px;
        }
      }

      .top-section {
        display: flex;
        position: relative;
        max-height: 210px;
        margin-bottom: 2px;

        .main-img {
          width: 100%;
          object-fit: contain;
          object-position: top;
        }
      }

      .middle-section {
        padding: 15px;

        .store-name {
          font-family: $font-700;
          font-size: 20px;
          line-height: 22px;
          text-align: center;
        }

        .title {
          font-family: $primary-font;
          font-size: 24px;
          // line-height: 100%;
          line-height: 1.3;
          text-align: center;
          color: $secondary;
          margin-bottom: 0;
          // font-family: arial, sans-serif;
        }

        .terms {
          font-family: $font-700;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: rgba($secondary, 0.6);
        }

        .terms-value {
          text-align: left;
          margin-bottom: 16px;
          p {
            font-family: $primary-font;
            font-size: 14px;
            line-height: 16.8px;
            text-align: left;
            color: #606669;
            margin: 0;
          }
          button {
            font-family: $font-600;
            font-size: 14px;
            line-height: 16.8px;
            color: #606669;
          }
        }

        .term-date {
          font-size: 14px;
          line-height: 14px;
          color: rgba($secondary, 0.6);
          padding-top: 5px;

        }

        .btn-grp {
          display: flex;
          gap: 13px;
          justify-content: center;

          &.native-mobile-view {
            button {
              font-size: 14px;
              line-height: 24px;
              // font-weight: 400;
              border-radius: 12px;
              padding: 6px 12px;

              &.in-app {
                border: 1px solid #239F55;
                background: #239F55;
                color: white;
              }

              &.safari {
                display: flex;
                align-items: center;
                border: 2px solid #2A78DA;
                background-color: white;

                img {
                  margin-right: 8px;
                }

                span {
                  line-height: 16.1px;
                  text-align: left;
                }
              }
            }

            &.direction-column {
              flex-direction: column;

              button {
                padding: 10px 20px;

                &.safari {
                  img {
                    margin-right: 15px;
                  }
                }
              }
            }
          }

          &.browser-view {
            button {
              text-align: center;
              background: #F1ECEC;
              padding: 8px 20px 8px 20px;
              gap: 0px;
              border-radius: 16px;
              border: 0px;
              display: flex;
              margin: auto;
              font-size: 18px;
              line-height: 32px;
              color: $secondary;

              &.cashrewards {
                background: #E9DCFB;
              }
            }
          }
        }

        a {
          font-family: ABC Monument Grotesk;
          font-size: 14px;
          line-height: 16px;
          text-align: center;
          display: flex;
          justify-content: center;
          margin: 15px 0px;
          text-decoration: underline;
          color: rgba($secondary, 0.6);
        }
      }

      .copy-text {
        font-size: 18px;
        line-height: 24px;
        color: rgba($secondary, 0.6);
        font-family: ABC Monument Grotesk;
        vertical-align: middle;
      }

      .copy-title {
        font-family: ABC Monument Grotesk;
        font-size: 24px;
        line-height: 1;
        color: $secondary;
      }

      .copy-check {
        margin-right: 8px;

      }

      .fly-box {
        padding: 35px 25px;
        text-align: center;
        font-family: ABC Monument Grotesk;

        &__title {
          font-size: 18px;
          line-height: 24px;
          color: rgba($secondary, 0.6);
          font-family: ABC Monument Grotesk;
        }

        &__desc {
          color: $secondary;
          font-size: 14px;
          line-height: 18px;
        }

        &__gif {
          max-width: 93%;
        }

        &__animation {
          text-align: left;
          position: relative;
          margin-bottom: 38px;
        }

        .store-img {
          bottom: -38px;
          right: 0;
          left: auto;
        }

      }
    }
  }
}
