.slider-container{
    .rc-slider {
        position: relative;
        width: 95%;
        height: 26px;
        margin: 10px 10px 0;
        padding: 5px 0;
        border-radius: 6px;
        touch-action: none;
        box-sizing: border-box;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    .rc-slider-rail {
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #808080;
        border-radius: 6px;
        border: 1px solid #808080;
        opacity: 0.5;
    }
    .rc-slider-handle:hover {
        border-color: #045651;
    }
    .rc-slider-track {
        position: absolute;
        height: 2px;
        background-color: #045651;
        border-radius: 6px;
    }
    .rc-slider-handle {
        position: absolute;
        width: 26px;
        height: 26px;
        margin-top: -12px;
        background-color: #fff;
        border: solid 2px #045651;
        border-radius: 50%;
        cursor: pointer;
        cursor: grab;
        opacity: 1;
        touch-action: pan-x;
    }
    .rc-slider-handle:focus-visible {
        border-color: #045651;
        box-shadow: none;
    }
    .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
        border-color: #045651;
        box-shadow: none;
    }
}
