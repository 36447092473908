.search-input-group {
  position: relative;
  z-index: 1050;
  
  .search-suggestions {
    position: absolute;
    top: 3rem;
    width: 100%;
    border-radius: 0;
    max-height: calc(100vh - 4rem);
    overflow: hidden;
    overflow-y: auto;
    background-color: white;
    @media (min-width: 1200px) {
      min-width: 375px;
    }
    .trendingHeading {
      padding: 1.75rem 1rem 0.813rem;
      font-family: 'ABC Monument Grotesk Black';
      font-size: 24px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: $secondary;
    }
  }
  li {
    cursor: pointer;
    border-radius: 0 !important;
    border: none;
    &:hover {
      background-color: rgba($main, 0.5);
    }
  }
}

img.search-result-logo {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
}

button#search-submit-btn {
  margin-left: -60px;
  width: 60px;
  z-index: 1051;
  background-color: transparent !important;
  border-radius: 0.5em !important;
  border: none !important;
  box-shadow: none !important;
  @media (max-width: 991px) {
    margin-left: -45px;
    width: 45px;
  }
}

.search-tab-cotainer {
  width: calc(100vw - 5px);
  height: 80px;
  overflow: auto;
  white-space: nowrap;
  padding-left: 0.75rem;
  .tab-pill {
    display: inline-block;
    padding : 0.75em 1em;
    border: 1px solid #808080;
    border-radius: 1.5em;
    font-size: 16px;
    font-weight: 400;
    color: #001412;
    margin-right: 1.5em;
    width: max-content;
    height: max-content;
    cursor: pointer;
    @media (max-width: 1199.98px) {
      margin-right: 0.625em
    }
    &.selected {
      color: #ffffff;
      background-color: #00322E;
      border: none;
    }
  }
}
.tab-sticky {
  position: sticky;
  z-index: 100;
  background-color: #f8f9fa;
  top: 88px;
  padding: 16px 0 10px;
  @media (max-width: 1199.98px) {
    width: calc(100vw - 5px);
    margin-left: -15px;
    padding: 16px 12px 0;
    top: 50px;
    background-color: #f8f9fa;
  }
}
.fixed-tab {
  top: 120px;
  margin: auto -15px;
}
