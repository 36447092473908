.isMobile {
  .listing-group-carousel {
    padding-top: 0.5em;
    padding-bottom: 1.5em;
  }
  .listing-card .card {
    box-shadow: 0 0.125rem 0.25rem rgba(0,0,0,0.075);
  }
  .sidebar {
    min-width: inherit;
    max-width: inherit;
    z-index: 2;
    .listing-group {
      flex-direction: row;
    }
    .listing-card {
      border: 1px solid rgba(151,151,151,0.17);
      &.view-all .card {
        background: transparent;
        border: none;
        box-shadow: none !important;
        color: white;
        font-size: 13px;
        .card-body {
          &:after {
            content: "";
            background: asset_url('../../../assets/images/icons/chevron-right.svg') no-repeat 50% 50%;
            height: 13px;
            width: 7px;
            background-size: contain;
            filter: invert(100%);
            margin-left: 5px;
          }
        }
      }
      &.card-similar .card.shadow-sm {
        box-shadow: none !important;
      }
    }
  }
  section {
    position: relative;
    z-index: 1;
    padding-bottom: 2em;
    &.advertisements-list {
      padding-bottom: 20px;
      overflow: hidden;
      .advert {
        margin-bottom: 0;
      }
    }
  }
  section[class*="bg-bird"] {
    margin-bottom: 0px !important;
    padding-bottom: 13px;
    &:after {
      width: 100%;
      height: 13px;
      background: #eaeaea;
      position: absolute;
      content:"";
      left: 0;
      bottom: 0;
    }
    &.top-features {
      padding-bottom: 0;
      &:after {
        height: 0 !important;
      }
    }
  }
  .my-calendar-sales {
    min-height: 190px;
    overflow: hidden;
  }
  .bg-bird-white {
    background: #fff;
    padding-bottom: 2em !important;
  }

  section[class*="bg-bird"] .btn-main.btn-lg {
    margin-top: 1em;
  }
  .newly-posted {
    z-index: 2;
  }

  /* Homepage */
  .homepage {
    background: white;
    section {
      padding-left: 15px;
      padding-right: 15px;
    }
    @media (max-width: 1024px) {
      section {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }

  /* Collection Detail */
  .collection-detail {
    .listing-collection {
      max-width: 214px;
    }
  }
  .listing-group-carousel.listing-group-collection {
    &.listing-group-collection-collection {
      .listing-collection {
        width: 235px;
        background: none;
        border: none;
        box-shadow: none !important;
      }
    }
  }
  /* Listing Detail */
  .listing-detail {
    .card-body {
      position: relative;
    }
    .image-container {
      width: 216px;
      margin: auto;
      .img-fluid {
        height: 184px;
        &.img-product {
          height: 216px;
        }
      }
      .carousel-control-prev, .carousel-control-next {
        display: none;
      }
    }
    .image-container-coupon {
      text-align: center!important;
      margin: auto;
      .img-fluid {
        height: 184px;
        &.img-product {
          height: 216px;
        }
      }
      .carousel-control-prev, .carousel-control-next {
        display: none;
      }
    }
    .btn-report .btn-report {
      padding: 1em 0 0 0;
    }
    .img-product-store {
      width: 64px;
      height: 64px;
      border: solid 0.5px rgb(151, 151, 151);
      position: absolute;
      right: 1.25em;
      top: 1.25em;
    }
  }
  #detail-accordion {
    .toggle-panel {
      all: unset;
      width: 100%;
    }
    .heading {
      text-decoration: none;
      color: $black;
      font-size: 16px;
      display: block;
      background: url('../../../assets/images/icons/icn_filter_plus.svg') no-repeat calc(100% - 1.5em);
      &.active {
        background: url('../../../assets/images/icons/icn_filter_minus.svg') no-repeat calc(100% - 1.5em);
      }
      &[aria-expanded*="true"] {
        background: url('../../../assets/images/icons/icn_filter_minus.svg') no-repeat calc(100% - 1.5em);
      }
    }
    .list-group-item {
      border-left: 0;
      border-right: 0;
    }
    .card-body {
      // background-image: linear-gradient(to bottom, rgb(255, 255, 255), rgb(237, 237, 237));
      &.bg-light {
        background: rgb(247, 247, 247);
      }
    }
  }
  .btn-back-to-top {
    bottom: 90px;
    right: 5px;
  }
}
/* Store and Brand Detail */
.noTransition {
  transition: none;
}

.halfModal {
  transition: none !important;
  margin: 0;
  .modal-content {
    position: fixed;
    top: 55%;
    height: 45%;
  }
}


/* Filters */
.sort-dropdown {
  display: flex;
  justify-content: flex-end;
  margin-right: 70px;
  select {
    width: auto;
    @media (min-width: 992px) {
      min-width: 210px;
    }
  }
}
.mobile-filter {
  float: left;
}
.btn-floating-filter {
  font-family: "ABC Monument Grotesk normal", sans-serif;
  border-radius: 0.5em;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 6px 12px;
  img {
    margin-left: 0.5em;
  }
}
@media (max-width: 1199.98px) {
  .mobile-filter, .sort-dropdown {
    width: calc(50% - 0.5em);
    margin-right: 0.5em;
    .btn-floating-filter, select {
      width: 100%;
    }
  }
  // For sticky filter and sort in mobile view
  .fixedFilter {
    position: sticky;
    z-index: 100;
    top: -250px;
    margin: auto -15px;
    padding: 0.25rem 0.75rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .filterSticky {
    background-color: #F0F3F0;
    top: 56px;
    &.search-tab {
      top: 120px;
    }
  }
  .sort-dropdown {
    background-color: white;
    border-radius: 0.5em;
  }
}

.toaster-modal {
  .modal-dialog {
    width: calc(100% - 15px);
    background-color: #ffffff;
    border-radius: 8px;
    top: 100px;
    border: none;
    .close-icon {
        height: 18px;
        width: 18px;
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: pointer;
        z-index: 99;
    }
    .modal-content {
      border: none;
      height: 100%;
      width: 100%;
      .modal-body {
        padding: 35px 18px;
      }
      #shippingMetaModalDescription {
        a {
          color: #0F78B2;
          text-decoration: underline;
        }
        * {
          margin: 0;
          padding: 0;
        }
      }
    }
  }
  @media (max-width: 767px) {
    transition: all .1s linear;
    top: auto;
    &.show .modal-dialog {
      bottom: -5px;
      opacity: 1;
    }
    .modal-dialog {
      min-width: calc(100vw - 15px);
      height: 400px;
      background-color: #ffffff;
      border: none;
      margin: 0 0.5em !important;
      top: auto;
      bottom: -500px;
      position: fixed;
      opacity: 0;
      -webkit-transition: opacity 0.3s ease-out, bottom 0.3s ease-out !important;
      -moz-transition: opacity 0.3s ease-out, bottom 0.3s ease-out !important;
      -o-transition: opacity 0.3s ease-out, bottom 0.3s ease-out !important;
      transition: opacity 0.3s ease-out, bottom 0.3s ease-out !important;
      .modal-content {
        border:none;
        height: 100%;
        width: 100%;
        .modal-body {
          padding: 35px 18px;
        }
      }
      &.modal-store-reviews {
        height: calc(100vh - 58px);
      }
    }
  }
  @media (min-width: 768px) {
    .modal-store-reviews {
      max-width: calc(100vw - 200px);
    }
  }
  @media (min-width: 1300px) {
    .modal-store-reviews {
      max-width: calc(100vw - 418px);
    }
  }
  @media (min-width: 1900px) {
    .modal-store-reviews {
      max-width: 1502px;
    }
  }
}

#filterModal {
  .filter-group {
    margin-top: 0;
    border-top: 1px solid $black-50;
  }
  &.fade .modal-dialog {
    transform: translate(0, 100vh);
  }
  &.show .modal-dialog {
    transform: none;
  }
  .modal-content {
    border: 0;
    border-radius: 1em;
    height: 100vh;
  }
  .modal-header {
    border-bottom: 0;
    .close {
      position: absolute;
      right: 0.5em;
      opacity: 1;
    }
  }
  .modal-footer {
    height: 73px;
  }
  .modal-body {
    padding: 0;
  }
  .btn-clear {
    margin-right: 3em !important;
  }
  .clear {
    margin-right: 3.5em !important;
  }
  .filter-group {
    .list-group-item {
      border-left: 0;
      border-radius: 0;
      border-right: 0;
      .header-label-wrapper:after {
        right: 1em;
        position: absolute;
      }
    }
    .header {
      padding: 0 !important;
      .header-label {
        padding: 0.5em 1em;
      }
    }
  }
}
