.card {
  border: 0;
  // box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08), 0px 10px 16px -4px rgba(0, 0, 0, 0.1);
  border-radius: 1em;
  .card-header {
    border-bottom-color: $black-50;
  }
}

.listing-card {
  margin-bottom: 1.5em;
  .few-left {
    // position: relative;
    .ribbon {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
      overflow: hidden;
      width: 195px;
      height: 195px;
      text-align: right;
    }
    .ribbon span {
      font-family: $font-600;
      color: #FFF;
      text-align: center;
      line-height: 55px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      width: 250px;
      display: block;
      background: #FF0000;
      position: absolute;
      top: 40px;
      right: -60px;
    }
  }
  .deal-rank {
    width: 18px;
    height: 45px;
    position: relative;
    left: 234px;
    display: flex;
    justify-content: center;
    z-index: 10;
    margin-bottom: -32px;
  }

  .card-img-top {
    position: relative;
    height: 175px;
    padding: 5px;
    .lazy-load-image-background {
      height: 100%;
      display: block !important;
    }
    img {
      height: 100%;
      object-fit: contain;
      width: 100%;
      border-radius: 1em 1em 0 0;
      &.covered {
        object-fit: cover;
      }
    }
  }
  .card-body {
    padding: 1em 1em 0 1em;
    &.deal{
      padding: 0px;
      .deal-name {
        height: 65px;
        padding: 10px 10px 0px;
      }
    }
    .card-text {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-size: 14px;
      height: 48px;
      line-height: 16px;
    }
    .price-was {
      font-size: 12px;
      margin-top: -4px;
      color: $secondary;
      .last-price-date {
        margin-left: 5px;
        font-weight: bold;
      }
      .check {
        height: 12px;
        width: 12px;
        margin-right: 2px;
      }
    }
    .doller-symbol {
      font-family: $font-700;
      font-size: 13px;
      line-height: 26px;
      font-weight: 700;
    }
    .cents {
      font-family: $font-700;
      font-size: 13px;
      line-height: 26px;
      font-weight: 700;
    }
    .update-price {
      display: flex;
      width: fit-content;
      max-width: 88px;
      height: 34px;
      padding: 3px;
      justify-content: center;
      align-items: center;
      border-radius: 4.927px;
      background: $pr-200;
      color: $se-800;
      border: 0;
      width:100%;
      span {
        text-align: left;
        // text-align: center;
        font-family: $font-600;
        font-size: 10px;
        font-style: normal;
        line-height: 10px;
      }
    }
    .deal-checking{
      font-size: 10px;
      line-height: 1.2;
      // padding:0 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      .dot-blink{
        margin-right: 5px;
      }
    }
    .deal-price-drop-label {
      // display: inline-block;
      height: 35px;
      background: #F6FDF3;
      width: fit-content;
      display: flex;
      padding: 3px;
      justify-content: center;
      align-items: center;
      max-width: 88px;
      width:100%;
      // gap: 4.094px;
      // flex-shrink: 0;
      border-radius: 4.927px;
      background: $main;

      &.deal-price-up-label {
        background: $psu-100;
      }
      img {
        width: 16px;
        height: 12px;
        margin-right: 4px;
      }
      .pricedrop {
        color: $secondary;
        font-family: $font-700;
        font-size: 14px;
        font-style: normal;
        // font-weight: 700;
        line-height: 24px;
        // font-size: 20px;
        // padding-left: 4px;
        &.pricedrop-2w{
          @media (max-width: 380px) {
            font-size: 10px;
          }
        }
      }
    }
    // .deal-price-drop-label {
    //   background: #F6FDF3;
    //   padding: 0px;
    //   padding-left: 10px;
    //   position: relative;
    //   height: inherit;
    //   width: fit-content;
    //   clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%);
    //   &.deal-price-up-label {
    //     background: $psu-100;
    //   }
    //   .pricedrop {
    //     color: $secondary;
    //     font-family: $font-600;
    //     font-size: 20px;
    //     padding-left: 4px;
    //   }
    //   img {
    //     height: 30px;
    //     width: 30px;
    //   }
    // }
  }
  .card-footer {
    background-color: transparent;
    padding: 0px;
    border-top: 0;
    align-items: center;
    display: grid;
    .card-meta-info {
      display: flex;
      margin: auto;
      width: 100%;
      height: inherit;
      background-color: #F0F3F0;
      border-radius: 0px 0px 16px 16px;
      font-size: 12px;
      align-items: center;
      justify-content: center;
      white-space: pre-wrap;
      padding: 0px 10px;
    }
    .sponsored-badge {
      img {
        margin-right: 5px;
      }
      color: $secondary;
      background-color: $white;
      padding: 4px 10px;
      border-radius: 8px;
      line-height: 16px;
    }
    .deal-store-nm {
      font-family: $font-600;
    }
  }

  .brand-store-logos {
    .brand-logo {
      margin-right: 0.5em;
      img {
        border: 1px solid $black-300;
        border-radius: 50em;
      }
    }
  }

  /* Members Box */
  .members-box {
    height: 170px;
    padding: 0.5em 1em;
    background: $pr-50;
    border-radius: 0.5em;
    text-align: center;
    img {
      width: 32px;
    }
    .desc {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .h3,
    .h4 {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    @media (min-width: 992px) {
      height: 160px;
      .h3,
      .h4 {
        margin-bottom: 0;
      }
    }
  }

  /* Store */
  /* Deal Hunter */
  &.deal-hunter {
    .profile-pic {
      width: 72px;
      height: 72px;
      border-radius: 72px;
      background: grey;
      margin: auto;
      margin-top: 25px;
    }
  }
}

.view-count {
  line-height: 16px;
  small {
    font-family: $primary-font;
    font-weight: 500;
    font-size: 10px;
    color: $se-800;
  }
  img {
    width: 14px;
    height: 10px;
    margin-right: 0.15em;
  }
}

.notify-event {
  color: #858586;
  font-size: 12px;
  font-weight: 300;
}

/* ---------------------------------------------------------------------- */
/* Sidebar */
/* ---------------------------------------------------------------------- */

.sidebar {
  /* Event Small */
  .card-event-sm {
    position: relative;
    min-width: 195px !important;
    @media (min-width: 1200px) {
      box-shadow: none !important;
    }
    .listing-image {
      width: 180px;
      height: 70px;
      object-fit: fill;
    }
    .card-title {
      font-size: 1em;
      .card-title-inner {
        min-height: 32px;
      }
    }
    .card-text {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      min-height: inherit !important;
    }
  }

  .listing-group {
    flex-direction: row;
    width: 100%;
    overflow: hidden;
    background: none;
    .listing-card {
      min-width: 130px;
      margin-right: 10px;
      box-shadow: $box-shadow;
    }
  }
  @media (min-width: 1200px) {
    .listing-group::-webkit-scrollbar {
      display: none;
    }
    .listing-group {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
  @media (max-width: 1199.98px) {
    .listing-group {
      overflow: scroll;
      padding-bottom: 1.5em;
    }
    .listing-group::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 0px;
      height: 8px;
      // display: none;
    }
    .listing-group::-webkit-scrollbar-thumb {
      border-radius: 20px;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
  }
}

// sale-event dont have price title
.card-title-sale-event {
  margin-top: 21px;
}

.filler-banner {
  height: 268px;
  object-fit: contain;
  width: 100%;
  @media (min-width: 992px) {
    height: 346px;
  }
}

/******** DEAL GLOBAL *******/
.listing-btm-user-image {
  object-fit: contain;
}

.featured-type {
  display: none;
}

.top-pick {
  .featured-type {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0.4rem;
  }
}

.scroll-button {
  cursor: pointer;
  img {
    height: 30px;
  }
}

.listing-details-floating-bar {
  &.hide {
    top: 0;
  }
  &.active {
    top: 70px;
  }
  display: flex;
  justify-content: space-between;
  transition: all 300ms;
  position: fixed;
  padding: 0.5rem 1.5rem;
  background-color: white;
  z-index: 1025;
  border-radius: 0 0 0.25rem 0.25rem;
  @media (min-width: 992px) {
    top: 101px;
    &.active {
      top: 101px;
    }
  }
  .component {
    &.price {
      font-size: 2.357142857142857em;
    }
    &.hide {
      display: none;
    }
  }
  @media (max-width: 575.98px) {
    .component {
      &.price {
        display: none;
      }
    }
    &.active {
      justify-content: center;
      // left: 0;
      // width: 100% !important;
      border-radius: 0;
    }
  }
}

.dot-blink {
  box-shadow: rgba(255, 167, 38, 1);
  transform: scale(1);
  width: 10px;
  min-width: 10px;
  height: 10px;
  border-radius: 50%;
  animation-name: pulse-orange;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  margin: auto;

  @keyframes pulse-orange {
    0% {
      background: rgba(255, 167, 38, 1);
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 121, 63, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(255, 121, 63, 0);
    }

    100% {
      background: rgba(255, 167, 38, 1);
      transform:  scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
    }
  }
}