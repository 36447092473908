.be-view {
  // margin-top: -33px;
  .back-nav {
    padding: 0 22px;
    img {
      height: 18px;
      margin-right: 6px;
    }
    color: $se-800;
    font-size: 12px;
  }
  .title {
    display: block;
    font-size: 22px;
    line-height: 133.4%;
    font-family: $font-700;
    color: $secondary;
    margin-top: 48px;
    margin-bottom: 50px;
    padding: 0 30px;
    &.enable {
      margin-bottom: 50px;
    }
    .sub-text {
      color: $pr-500;
    }
    .sub-header {
      font-size: 14px;
      color: $secondary;
      margin-top: 10px;
      line-height: 21px;
      font-weight: 400;
      margin-right: 35px;
    }
    &.second {
      margin-top: 10px;
      margin-bottom: 12px;
      padding: 0 43px 0px 30px;
    }
    &.header {
      position: absolute;
    }
  }
  .sub-title {
    display: block;
    text-align: left;
    font-size: 14px;
    color: $opaque-black;
    padding: 0 51px 0px 30px;
    line-height: 20px;
    u {
      cursor: pointer;
    }
    &.dark {
      color: $secondary;
      font-size: 16px;
      width: 300px;
      margin-bottom: 30px;
    }
  }
  .active-be-img {
    width: 100%;
    padding: 0px 20px;
  }
  .steps-view {
    padding: 0 15px 0 30px;
    margin-top: 21px;
    margin-left: 45px;
    .step-title {
      font-size: 16px;
      font-weight: 400;
      color: $secondary;
      margin-bottom: 22px;
      letter-spacing: 0.15px;
      .info {
        font-style: italic;
        display: block;
        font-size: 12px;
        margin-top: -3px;
      }
    }
    .review-link {
      display: block;
      text-align: center;
      font-size: 14px;
      color: $opaque-black;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .move-to-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-color: $black-50;
    color: $opaque-black;
    font-size: 14px;
    margin-left: 75px;
    border-radius: 15px;
    a {
      text-decoration: underline;
      font-family: $font-700;
    }
  }
  .down-arrow {
    position: fixed;
    // max-height: 40px;
    max-height: 77px;
    left: 35px;
    // margin-top: 10px;
    bottom: 15px;
  }
  .up-arrow {
    align-self: flex-end;
    height: 26px;
    margin-right: 60px;
    margin-top: 20px;
  }
  .icon-bt {
    display: flex;
    height: 42px;
    width: 250px;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin: 35px auto;
    background-color: $secondary;
    border-radius: 24px;
    cursor: pointer;
    img {
      height: 22px;
      width: 19px;
      margin-right: 10px;
    }
    span {
      color: $white;
      font-size: 15px;
      text-transform: uppercase;
    }
  }
  .action-help {
    display: flex;
    gap: 5px;
    position: fixed;
    top: 70%;
    background: $secondary;
    color: $white;
    right: 0;
    padding: 10px;
    border-radius: 24px 0px 0px 24px;
    justify-content: center;
    align-items: center;
  }
  .enable-extension{
    padding: 28px 51px 0 30px;
    .title {
      position:absolute;
    }
  }
  .page-badge {
    height: 30px;
    width:30px;
    border-radius: 50%;
    background-color: $black-50;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 28px;
    float: right;
    margin-top: -10px;
  }
  .badge-text{
    font-size: 15px;
  }
  .steps{
    padding: 0;
    list-style-type: none;
    margin-top: -10px;
  }
  .step {
    font-size: 15px;
    margin-bottom: 4px;
    .step-sub-title {
      color: $pr-500;
      font-weight: 600;
    }
   .bold {
      font-family: $font-600;
      color: $secondary;

     .small {
       font-size: 12px;
     }
    }
    .ext-icon{
      margin: 5px;
      height: 14px;
      width: 17px;
    }
    .sub-text{
      font-size: 12px;
      font-style: italic;
    }
    .ext-enable{
      margin-left: 10px;
      position: absolute;
      top: -10px;
      height: 38px;
      width: auto;
    }
  }
  .info-text {
    color: $pr-500;
    margin-left: 6px;
    font-size: 16px;
  }
  .walkthrough-container{
    width:88%;
    margin-right: 28px;
    margin-left: 25px;
    height: 240px;
  }
  .walkthrough {
    width: 100%;
    mix-blend-mode: multiply;
  }
  .pointer-box{
    position: fixed;
    max-height: 77px;
    left: 35px;
    bottom: 75px;
    pointer-events: none;

    @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
      bottom: 60px;
      left:32px
    }

  }
  .bird-arrow{
    height: 150px;
    width: 137px;
    pointer-events: none;

    @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
      height: 130px;
      width: 122px;
    }
  }
  .privacy-policy{
    font-size: 14px;
    margin-bottom: 0;
    color: $black-10;

    .privacy-link{
      text-decoration: underline;
      font-weight: bold;
    }

    @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
      font-size: 12px;
      line-height: 15px;
    }
  }

  .bird-flaying{
    position: absolute;
    top: 10px;
    right: 56px;
    z-index: 999;
    height: 150px;
  }

  .up-arrow{
    position: absolute;
    top: -7px;
    right: -23px;
    z-index: 999;
    height: 37px;
    width: 13px;
  }
}
