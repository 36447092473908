.btn {
  color: $se-800;
  &:focus {
    box-shadow: none;
  }
  img {
    margin-left: 0.25em;
  }
}
.btn, .btn-lg {
  height: 48px;
  font-size: 1rem;
  line-height: 45px;
  padding: 0 16px;
  font-family: $font-600;
  &:not(.btn-backend) {
    border-width: 1.5px;
  }
  transition: all 300ms;
  white-space: nowrap;
  a {
    text-decoration: none;
  }
}
.btn-sm {
  height: 32px;
  font-size: 12px;
  padding: 0.25rem 0.5rem;
  line-height: 1em;
  align-items: center;
  display: flex;
}

.btn-outline-dark {
  color: $black;
  background-color: white;
  background-image: none;
  border-radius: 35px;
  border: 1px solid $black-500;
  &:hover {
    background-color: $black-100;
    color: $black;
  }
  &.more {
    background:url('../../../assets/images/icons/chevron-down.svg') no-repeat 80% center;
  }
  &.less {
    background:url('../../../assets/images/icons/chevron-up.svg') no-repeat 80% center;
  }
  &.down {
    background:url('../../../assets/images/icons/chevron-down.svg') no-repeat 85% center;
  }
 }
 @media (max-width: 425px) {
   .claimButton {
     margin-top: 1em;
   }
 }
.btn-main {
  background-color: $pr-200;
  color: $se-800;
  &:hover {
    background-color: $main;
  }
  &.active {
    background-color: $main;
  }
  &.inactive {
    background: $main-inactive
  }
  img + span {
    margin-left: 0.5rem;
  }
}

.btn-view-all {
  width: 233px;
  height: 48px;
  font-size: 16px;
  color: $se-800;
}

.btn-show-more {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: $se-800;
  width: fit-content;
  padding : 12px 16px;
  display: block;
  margin : 16px auto;
  text-align: center;
  cursor: pointer;
}

.btn-secondary {
  background-color: $secondary;
  color: white;
  border-color: $secondary;
  &:hover {
    background: $se-400;
  }
}
.btn-peach {
  background: $peach;
  &.add-to-calendar {
    font-weight: 400 !important;
    background: $peach url('../../../assets/images/icons/icn-calendar.svg') no-repeat 12px 37%;
    background-size: 15px 17px;
    padding-left: 35px;
    &.active {
    }
  }
}
.btn-social {
  width: 30px;
  height: 30px;
  padding: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  &:first-child {
    width: auto;
  }
}

a.login-button {
  text-decoration: none !important;
}


.pointer {
  cursor: pointer;
}

button.anchor-button {
  background-color: transparent;
  border: none;
  color: $main;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
  outline: 0;
  &:hover, &:focus, &:active {
    text-decoration: none;
  }
}

.btn-follow {
  width: 48px;
  height: 48px;
  border-radius: 48px;
  padding-left: 0;
  padding-right: 0;
  display: inline-table;
  border: 1px solid $black-500;
  background-color: white;
  .btn-content {
    display: flex;
    width: 45.5px;
    justify-content: center;
    align-items: center;
    height: 45.5px;
  }
  img {
    margin-left: 0;
  }
  &.active {
    background-color: $peach !important;
    border-color: $peach !important;
  }
  &:hover {
    background-color: $black-100;
  }
}

.btn-follow-coupon {
  &.active {
    color: $black !important;
    background-color: $peach !important;
    border-color: $peach !important;
  }
}

.btn-copy {
  margin-right: 0.5em;
  img {
    margin: 0px;
  }
  &:hover {
    background-color: $black-50;
    cursor: pointer;
    border-radius: 0.5em;
  }
}

.btn-back-to-top {
  background: $secondary;
  position: fixed;
  z-index: 1000;
  right: 1em;
  bottom: 1em;
  width: 50px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  img {
    filter: invert(100%);
    margin: 0;
  }
}
@media (max-width: 767.98px) {
  .listing-detail + .btn-back-to-top {
    bottom: 5em;
  }
}
