.goto-page-container {
    .title-card {
      background-color: #dcfad2;
      padding: 16px 45px;
      width: fit-content;
      margin: 0 auto;
      border-radius: 16px;
      .title {
        font-weight: 800;
        font-size: 24px;
        text-align: center;
        color: #001412;
      }
      .tick-image {
        height: 18px;
        width: 18px;
        padding: 4px;
        background-color: #1d9a4e;
        border-radius: 34px;
      }
    }
    .fly-container {
      position: relative;
      max-width: 500px;
      margin: 0px auto 38px;
      .fly-img {
        width: 100%;
        margin: 1rem auto -28px;
        padding: 0px 40px;
      }
      .from-img {
        width: 6rem;
      }
      .to-img {
        width: 6rem;
        margin-right: 10px;
      }
      @media (max-width: 414px) {
        .fly-container {
          width: 100%;
        }
        .from-img {
          width: 4rem;
        }
        .to-img {
          width: 4rem;
          margin-right: 5px;
        }
        .fly-img {
          padding: 0px 16px;
          margin-bottom: -20px;
        }
      }
    }
    .short-description {
      display: block;
      text-align: center;
      margin: 0 auto 9px;
      color: #001412;
      font-size: 16px;
      font-weight: 400;
    }
  }