/******* ACCOUNT MENU *******/
.settings-page {
  @media (min-width: 992px) {
    margin-top: 1.5em;
  }
  .account-menu {
    margin-bottom: 2.5em;
    .list-group-item {
      border-width: 0 0 1px 0;
      border-bottom: 1px solid $black-50;
      margin-bottom: 0;
      text-decoration: none;
      &.active {
        background: none;
        border-bottom: 1px solid $black-50;
        color: $se-800;
      }
      &:not(:first-child) {
        &.active img {
          filter: invert(60%) sepia(35%) saturate(846%) hue-rotate(91deg) brightness(92%) contrast(98%);
        }

      }
    }
  }
  .settings-card {
    box-shadow: none;
    border: 1px solid $black-500;
    border-radius: 2em;
    .card-body {
      padding: 2em 1.5em;
    }
  }
  .email-notif-switch {
    display: flex;
    flex-direction: row;
    align-items: center;
    .switch {
      display: flex;
      flex-basis: 60px;
      flex-grow: 0;
      flex-shrink: 0;
      position: relative;
      width: 60px;
      height: 34px;
    }

    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: .4s;
      transition: .4s;
    }
    .slider:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }
    input:checked + .slider {
      background-color: $pr-500;
    }
    input:focus + .slider {
      box-shadow: 0 0 1px $pr-500;
    }
    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
    .slider.round {
      border-radius: 34px;
    }
    .slider.round:before {
      border-radius: 50%;
    }
    .switch-label {
      font-size: 14px;
      margin-left: 10px;
    }
  }
}


.account-event {
  .event-share {
    position: relative;
    .action {
      position: absolute;
      right: 15px;
      bottom: 0;
    }
  }
}

/** My Calendar **/
.user-cal-clock {
  background: $main;
  display: inline-flex;
  border-radius: 50em;
  margin-bottom: 1em;
  padding: 0.25em 0.5em;
  font-size: 12px;
  line-height: 1em;
  font-family: $font-600;
  align-items:center;

}
/** For Delete pop up **/
.underline-text {
  text-decoration: underline !important;
  font-size: 12px;
  cursor: pointer;
}

.delete-text {
  color: $se-800;
  margin-bottom: 30px !important;
}
.btn-size {
  font-size: 12px !important;
  height: 32px;
  line-height: 31px;
  padding: 0px 8px;
}
