.seo-content {
  h1 {
    color: $secondary;
    text-align: left;
  }
  .seo-text {
    text-align: center;
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
    color: #335B58;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 125px;
  }
  .remove-clamp {
    -webkit-line-clamp: unset;
    max-height: none;
  }
}
