.cba-exclusive {
  margin-top: 0.5em !important;
  max-width: 1600px;
  margin: auto;
  .section-bg {
    background: #FEFDEE;
    height: 585px !important;
    @media (min-width: 992px) {
      height: 525px !important;
    }
  }
  .section-group-body {
    margin-top: calc(-585px + 2.5em) !important;
    height: calc(585px - 2.5em) !important;
    @media (min-width: 992px) {
      margin-top: calc(-525px + 2.5em) !important;
      height: calc(525px - 2.5em) !important;
    }
  }
  .cba-heading {
    font-weight: 800;
    color: $secondary;
    line-height: 32px;
  }
  .subtitle {
    font-family: 'ABC Monument Grotesk';
    font-weight: 400;
    color: $se-800;
    font-size: 14px;
    line-height: 20px;
  }
  .cba-logo {
    width: 140px;
    @media (min-width: 992px) {
      width: 190px;
    }
  }
}