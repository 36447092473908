.top-50 {
  // margin-top: 0.5em !important;
  .section-bg {
    background: $se-50;
    height: 510px !important;
    @media (min-width: 992px) {
      height: 520px !important;
    }
    &.your-top-section-bg {
      background: #FEF4F4;;

    }
    &.manual-bg {
      height: 562px !important;
      @media (min-width: 768px) {
        height: 520px !important;
      }
    }
  }
  .dept-top-10 {
    height: 588px;
    border-radius: 2em;
    @media (min-width: 768px) {
      height: 560px !important;
    }
  }
  .dept-top-10-for-you {
    height: 568px;
    border-radius: 2em;
    @media (min-width: 768px) {
      height: 520px !important;
    }
  }
  .your-top-10 {
    height: 510px !important;
    @media (min-width: 768px) {
      height: 560px !important;
    }
  }
  .section-group-body {
    margin-top: calc(-510px + 43px) !important;
    height: calc(510px - 40px) !important;
    @media (min-width: 992px) {
      margin-top: calc(-520px + 2.5em) !important;
      height: calc(520px - 2.5em) !important;
    }
  }
  .dept-group-body {
    margin-top: calc(-588px + 29px) !important;
    height: calc(588px - 27px) !important;
   @media (min-width: 768px) {
    margin-top: calc(-560px + 2.5em) !important;
    height: calc(560px - 2.5em) !important;
  }
 }
 .manual-merch-body {
  margin-top: calc(-562px + 29px) !important;
  height: calc(562px - 27px) !important;
  @media (min-width: 768px) {
   margin-top: calc(-520px + 2.5em) !important;
   height: calc(520px - 2.5em) !important;
 }
 }
  .dept-group-body-for-you {
    margin-top: calc(-568px + 29px) !important;
    height: calc(568px - 27px) !important;
    @media (min-width: 768px) {
     margin-top: calc(-520px + 2.5em) !important;
     height: calc(520px - 2.5em) !important;
    }
  }
  .your-top-10-group-body {
    margin-top: calc(-510px + 23px) !important;
    height: calc(510px - 24px) !important;
   @media (min-width: 768px) {
    margin-top: calc(-560px + 2.5em) !important;
    height: calc(560px - 2.5em) !important;
  }
  }
  // for counts
  .dept-counts {
    display: inline-block;
  }
  @media (max-width: 991.98px) {
    .card-coupon .members-box {
      height: 217px !important;
    }
  }
  .carousel-title {
    font-family: $font-800;
    color: $secondary;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .subtitle {
    font-size: 14px;
  }
  .btn-view-all-mobile {
    margin-top: -7px;
  }
}
