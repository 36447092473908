img {
  &.img-20 {
    object-fit: contain;
    height: 20px;
    width: 20px;
  }

  &.img-70 {
    object-fit: contain;
    height: 70px;
    width: 70px;
  }

  &.img-40 {
    object-fit: contain;
    height: 40px;
    width: 40px;
  }

  &.img-30 {
    object-fit: contain;
    height: 30px;
    width: 30px;
  }

  &.img-width-45 {
    width: 45px;
  }
}